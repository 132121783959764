import { user } from "../../../../helpers/business/interfaces";
import { getUser } from "../getUserData";
import { postUser } from "../userDB";

export const unFollowUser = async (otherUID: string, myUser: user) => {
    if (myUser.following.includes(otherUID))
        myUser.following = myUser.following.filter((uid) => uid !== otherUID);

    getUser(otherUID).then((otherUser) => {
        if (!otherUser) return;
        if (otherUser.followers.includes(myUser.UID))
            otherUser.followers = otherUser.followers.filter(
                (uid: string) => uid !== myUser.UID
            );
        postUser(
            otherUser.UID,
            otherUser.firstname,
            otherUser.lastname,
            otherUser.email,
            otherUser.username,
            otherUser.followers,
            otherUser.following
        );
    });
    postUser(
        myUser.UID,
        myUser.firstname,
        myUser.lastname,
        myUser.email,
        myUser.username,
        myUser.followers,
        myUser.following
    );
};
