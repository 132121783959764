import {
    query,
    where,
    collection,
    getDocs,
    Timestamp,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { session } from "../../../helpers/business/interfaces";
import { programDB } from "./getPrograms";

export interface sessionDB extends session {
    owner: string;
}

export const getProgramsFromUsers = async (
    UIDs: string[],
    setSessions: React.Dispatch<React.SetStateAction<programDB[]>>
) => {
    // Get documents from collection "programs" where the user is a member of the document ID
    const res: programDB[] = [];

    const sessions = collection(firestore, "program");

    await UIDs.forEach(async (UID) => {
        const q = query(sessions, where("owner", "==", UID));
        return await getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data() as programDB;
                if (data === undefined) {
                    return;
                }
                data.dateCreated = new Date(
                    (data.dateCreated as unknown as Timestamp).seconds * 1000
                );

                res.push(data);

                setSessions([...res]);
            });
        });
    });
};
