type SubHeaderProps = {
    text: string;
    type?: string;
};

function SubHeader({ text, type }: SubHeaderProps) {
    const blue = `text-2xl font-medium underline decoration-blue-400 dark:text-slate-200`;
    const orange = `text-2xl font-medium underline decoration-orange-400 dark:text-slate-200`;

    return <h2 className={type === "program" ? blue : orange}>{text}</h2>;
}

export default SubHeader;
