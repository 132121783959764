import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { session } from "../../../helpers/business/interfaces";

const sessionDB = collection(firestore, "session");
// This is just a helper to add data to the firestore database

export const postSession = async (UID: string, session: session) => {
    await setDoc(doc(sessionDB), {
        //Tror faktisk det er best å la databasen generere unike nøkler selv her!
        ...session,
        owner: UID,
    }).then(() => {
        console.log("Document successfully written!");
    });
};
