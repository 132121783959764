import { sessionDB } from "../../firebase/firestore/sessions/getSessions";
import { program, user } from "../../helpers/business/interfaces";
import GetStreak from "../users/getStreak";

interface TrainingDataProps {
    userData?: user | null;
    sessions?: sessionDB[] | null;
    programs?: program[] | null;
}

export const TrainingData = ({
    userData,
    sessions,
    programs,
}: TrainingDataProps) => (
    <>
        {userData && sessions && programs && (
            <div className="mt-4 w-1/4">
                <p className="mb-2">
                    <span className=" font-bold">Antall programmer:</span>{" "}
                    {programs.length}
                </p>
                <p className="my-2">
                    <span className=" font-bold">Antall økter:</span>{" "}
                    {sessions.length}
                </p>
                <p className="my-2">
                    <span className=" font-bold">Streak:</span> <GetStreak sessions={sessions}/>
                </p>
            </div>
        )}
        {!userData && <p className="italic">Ingen bruker funnet</p>}
    </>
);
