import { program } from "../../helpers/business/interfaces";
import Header from "../header";
import { ProgramCard } from "./programCard";

interface OtherUsersProgramsProps {
    programs: program[];
    currentUser: string;
    followers: string[];
}

const OtherUsersPrograms = ({
    programs,
    currentUser,
    followers,
}: OtherUsersProgramsProps) => {
    return (
        <div className="mt-8">
            <div className="flex justify-between">
                <Header text={"Treningsprogrammer"} />
            </div>
            {programs
                .filter(
                    (p) =>
                        p.visibility === "public" ||
                        (p.visibility === "followers" &&
                            followers.includes(currentUser))
                )
                .sort(
                    (o1, o2) =>
                        o2.dateCreated.getTime() - o1.dateCreated.getTime()
                )
                .map((program) => {
                    return (
                        <ul key={program.name}>
                            <ProgramCard program={program} />
                        </ul>
                    );
                })}
            {programs.length == 0 && (
                <p className="italic mt-4">Ingen registrerte programmer</p>
            )}
        </div>
    );
};

export default OtherUsersPrograms;
