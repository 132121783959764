export function minuteDiff(date1: Date, date2: Date): number {
    return Math.round(Math.abs(date1.getTime() - date2.getTime()) / 6e4);
}

export function dateDiffString(date1: Date, date2: Date): string {
    const hrs = Math.floor(minuteDiff(date1, date2) / 60);
    const mins = Math.round(minuteDiff(date1, date2) % 60);

    let hrsPart = "";
    if (hrs == 1) {
        hrsPart = hrs.toString() + " time";
    } else if (hrs > 1) {
        hrsPart = hrs.toString() + " timer";
    }

    let minPart = "";
    if (mins == 1) {
        minPart = mins.toString() + " minutt";
    } else if (mins > 1) {
        minPart = mins.toString() + " minutter";
    }

    const diffStr =
        hrsPart + (hrsPart === "" || minPart === "" ? "" : " og ") + minPart;
    return diffStr ? diffStr : "Under 1 minutt";
}
