/**
 * Defines the type of NavLinkType.
 */
export interface NavLinkType {
    label: string;
    url: string;
}

/**
 * The links to be displayed in the navbars.
 */
export const navLinks: NavLinkType[] = [
    {
        label: "Hjem",
        url: "/home",
    },
    {
        label: "Brukersøk",
        url: "/userSearch",
    },
    {
        label: "Om oss",
        url: "/about",
    },
    {
        label: "Min profil",
        url: "/profile",
    },
    {
        label: "Mine program",
        url: "/programs",
    },
    {
        label: "Mine økter",
        url: "/sessions",
    },
];
