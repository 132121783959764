import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/authContext";
import { darkModeStyle } from "../../helpers/style/darkmode";

export const LogInInputs = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { signIn } = UserAuth();
    const navigate = useNavigate();

    const handleLogIn = async () => {
        try {
            await signIn(email, password).then(() => {
                navigate("/home");
            });
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className=" max-w-md mt-4">
            <input
                type="text"
                placeholder="E-post"
                value={email}
                className={`border-2 border-gray-300 p-2 rounded-lg w-full mt-4 ${darkModeStyle}`}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
            />
            <input
                type="password"
                placeholder="Passord"
                value={password}
                className={`border-2 border-gray-300 p-2 rounded-lg w-full mt-4 ${darkModeStyle}`}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button
                className="bg-orange-600 text-white p-2 rounded-lg w-full mt-4 hover:bg-orange-500"
                onClick={handleLogIn}
                id="logInButton"
            >
                Logg inn
            </button>
            <NavLink to="/create-user">
                <button className="text-orange-600 p-2 w-full mt-4 border border-orange-500 rounded-lg hover:underline">
                    Ny bruker
                </button>
            </NavLink>
        </div>
    );
};
