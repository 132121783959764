import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { program } from "../../../helpers/business/interfaces";

const programDB = collection(firestore, "program");
// This is just a helper to add data to the firestore database

export const postProgram = async (
    UID: string,
    program: program,
) => {
    await setDoc(doc(programDB, program.name + "#" + UID), {
        ...program,
        owner: UID,
    }).then(() => {
        console.log("Document successfully written!");
        window.location.href = "/home";
    });
};
