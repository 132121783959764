import { completedExercise, exercise } from "../../helpers/business/interfaces";

interface ExerciseTableProps {
    exercises: exercise[];
    color?: "orange" | "blue";
}

export const ExerciseTable = ({ exercises, color }: ExerciseTableProps) => {
    const orange = `w-32 border-b border-orange-400 text-left`;
    const blue = `w-32 border-b border-blue-400 text-left`;

    const thStyle = color === "blue" ? blue : orange;
    return (
        <table className=" mt-4">
            <thead>
                <tr>
                    <th className={thStyle}>Nummer</th>
                    <th className={thStyle}>Øvelse</th>
                    <th className={thStyle}>Sets</th>
                    <th className={thStyle}>Reps</th>
                    {exercises[0] !== undefined &&
                    (exercises as completedExercise[])[0].sessionValues !==
                        undefined ? (
                        <th className={thStyle + " w-max"}>Vekter</th>
                    ) : null}
                </tr>
            </thead>

            <tbody>
                {exercises.map((e: exercise, i: number) => (
                    <TableRow
                        key={e.label}
                        exercise={e}
                        idx={i + 1}
                        color={color}
                    />
                ))}
            </tbody>
        </table>
    );
};

function TableRow({
    exercise,
    idx,
    color,
}: {
    exercise: exercise;
    idx: number;
    color?: "blue" | "orange";
}) {
    const blue = `border-b border-blue-400`;
    const orange = `border-b border-orange-400`;

    return (
        <tr className={color === "blue" ? blue : orange}>
            <td>{idx}</td>
            <td>{exercise.label}</td>
            <td>{exercise.sets}</td>
            <td>{exercise.reps}</td>
            {(exercise as completedExercise).sessionValues !== undefined ? (
                <td>
                    {(exercise as completedExercise).sessionValues
                        .map((e) => {
                            if (e > 0) {
                                return e.toString() + "kg";
                            } else {
                                return "vektløs";
                            }
                        })
                        .join(", ")}
                </td>
            ) : null}
        </tr>
    );
}
