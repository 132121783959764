import { useState, useEffect } from "react";
import { getUser } from "../../firebase/firestore/users/getUserData";
import { session } from "../../helpers/business/interfaces";
import { dateDiffString } from "../../helpers/date/calculations";
import { user } from "../../helpers/business/interfaces";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { ExerciseTable } from "./exerciseTable";
import { DetailView } from "./exerciseView";
import { dateTimeToTimeSince } from "../../helpers/functions";
import profilbilde from "../../img/profilbilde.jpeg";

export const SessionCard = ({ session }: { session: session }) => {
    const [user, setUser] = useState<user | null>(null);
    const auth = getAuth();
    const ownSession = auth.currentUser?.uid === session.owner;

    useEffect(() => {
        if (session.owner && !ownSession) {
            getUser(session.owner).then((user) => {
                setUser(user);
            });
        }
    }, [auth.currentUser]);

    return (
        <div className="px-8 my-8 shadow-lg pb-8 dark:shadow-gray-400 dark:shadow-sm">
            <div className="bg-orange-200 -mx-8 p-4 mb-4 pl-6 flex justify-between">
                {ownSession && <p>Din økt</p>}
                {user && !ownSession && (
                    <Link to={`/user?uid=${user.UID}`}>
                        <span className="text-black self-center inline-flex items-baseline hover:underline">
                            <img
                                src={profilbilde}
                                className="m-[-10px] mr-1 w-9 h-9 rounded-full self-center"
                            ></img>
                            {user.username}
                        </span>
                    </Link>
                )}
                <p className="text-gray-700">
                    {dateTimeToTimeSince(session.dateCreated)}
                </p>
            </div>
            <div className="flex justify-between">
                <h2 className="text-2xl font-medium dark:text-slate-200">
                    {session.programName}
                </h2>
                <div className="flex gap-1">
                    <span className=" text-2xl">🗓</span>
                    <p className="text-gray-500 -mr-4 mt-1">
                        {session.startTime.toLocaleDateString("nb-NO", {
                            month: "long",
                            day: "numeric",
                        })}
                    </p>
                </div>
            </div>
            <p className=" opacity-40 mt-2 dark:opacity-100">
                Lengde: {dateDiffString(session.startTime, session.endTime)}
            </p>
            <p className=" opacity-40 mt-2 dark:opacity-100">
                Antall øvelser: {session.completedExercises.length} - Total
                vekt:{" "}
                {session.completedExercises
                    .map((ce) =>
                        ce.sessionValues.reduce((sum, part) => sum + part, 0)
                    )
                    .reduce((sum, part) => sum + part, 0)}
                kg
            </p>
            <p className="my-2 dark:opacity-100">{session.description}</p>
            <DetailView>
                <ExerciseTable exercises={session.completedExercises} />
            </DetailView>
        </div>
    );
};
