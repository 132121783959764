import { useEffect, useState } from "react";
import { followUser } from "../../firebase/firestore/users/following/followUser";
import { unFollowUser } from "../../firebase/firestore/users/following/unFollowUser";
import { user } from "../../helpers/business/interfaces";

interface FollowButtonsProps {
    userData?: user | null;
    currentUser?: user | null;
}

export const FollowButton = ({ userData, currentUser }: FollowButtonsProps) => {
    const [toggleButton, setToggleButton] = useState(true);

    useEffect(() => {
        if (userData && currentUser) {
            if (currentUser.following.includes(userData.UID)) {
                setToggleButton(false);
            }
        }
    }, [userData, currentUser]);

    const handleFollow = () => {
        setToggleButton(!toggleButton);
        if (userData && currentUser) followUser(userData.UID, currentUser);
    };

    const handleUnfollow = () => {
        setToggleButton(!toggleButton);
        if (userData && currentUser) unFollowUser(userData.UID, currentUser);
    };
    return (
        <>
            {userData && (
                <>
                    {toggleButton ? (
                        <button
                            className="w-32 p-2 rounded-lg  text-white bg-blue-600 hover:bg-blue-500 border border-blue-600 dark:bg-transparent dark:text-blue-600 hover:dark:text-slate-800 hover:dark:bg-blue-600"
                            type="submit"
                            onClick={handleFollow}
                        >
                            Følg
                        </button>
                    ) : (
                        <button
                            className="w-32 p-2 rounded-lg  text-white bg-red-600 hover:bg-red-500 border border-red-600 dark:bg-transparent dark:text-red-600 hover:dark:text-red-800 hover:dark:bg-red-600"
                            type="submit"
                            onClick={handleUnfollow}
                        >
                            Slutt å følge
                        </button>
                    )}
                </>
            )}
        </>
    );
};
