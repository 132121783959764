import { useEffect, useState } from "react";
import { program } from "../../helpers/business/interfaces";
import { user } from "../../helpers/business/interfaces";
import { getUser } from "../../firebase/firestore/users/getUserData";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { ExerciseTable } from "./exerciseTable";
import { DetailView } from "./exerciseView";
import { dateTimeToTimeSince } from "../../helpers/functions";
import profilbilde from "../../img/profilbilde.jpeg";

const intensityToText = (intensity: number) => {
    switch (intensity) {
        case 1:
            return "Svært lav";
        case 2:
            return "Lav";
        case 3:
            return "Middels";
        case 4:
            return "Høy";
        case 5:
            return "Svært høy";
        default:
            return "Ukjent";
    }
};

const minutesToText = (minutes: number) => {
    const hours = minutes / 60;
    const hoursRounded = Math.floor(hours);
    const minutesRounded = Math.round((hours - hoursRounded) * 60);

    if (hoursRounded === 0) {
        return `${minutesRounded} min`;
    }
    if (minutesRounded === 0) {
        return `${hoursRounded} timer`;
    }
    if (hoursRounded === 0 && minutesRounded === 0) {
        return "0";
    }
    if (hoursRounded === 1) {
        return `${hoursRounded} time og ${minutesRounded} min`;
    }

    return `${hoursRounded} timer og ${minutesRounded} min`;
};

export const ProgramCard = ({
    program,
    children,
}: {
    program: program;
    children?: JSX.Element[] | JSX.Element;
}) => {
    const [user, setUser] = useState<user | null>(null);
    const auth = getAuth();
    const ownProgram = auth.currentUser?.uid === program.owner;

    useEffect(() => {
        if (program.owner && !ownProgram) {
            getUser(program.owner).then((user) => {
                setUser(user);
            });
        }
    }, [auth.currentUser]);

    const privacyText = () => {
        switch (program.visibility) {
            case "public":
                return "Offentlig";
            case "private":
                return "Privat";
            case "followers":
                return "Kun følgere";
            default:
                return "Ukjent";
        }
    };

    return (
        <div className="px-8 my-8 shadow-lg pb-8 dark:shadow-gray-400 dark:shadow-sm">
            <div className="bg-blue-200 -mx-8 p-4 mb-4 pl-6 flex justify-between">
                {ownProgram && (
                    <p className="text-black">
                        Ditt program - <span>{privacyText()}</span>
                    </p>
                )}
                {user && !ownProgram && (
                    <Link to={`/user?uid=${user.UID}`}>
                        <span className="text-black self-center inline-flex items-baseline hover:underline">
                            <img
                                src={profilbilde}
                                className="m-[-10px] mr-1 w-9 h-9 rounded-full self-center"
                            ></img>
                            {user.username}
                        </span>
                    </Link>
                )}
                <p className="text-gray-700">
                    {dateTimeToTimeSince(program.dateCreated)}
                </p>
            </div>
            <h2 className="text-2xl font-medium dark:text-slate-200">
                {program.name}
            </h2>
            <p className="opacity-40 mt-2 dark:opacity-100">
                Kategori: {program.category} - Tid:{" "}
                {minutesToText(program.duration)} - Intensitet:{" "}
                {intensityToText(program.intensity)}
            </p>
            <p className="opacity-40 mt-2 dark:opacity-100">
                Antall øvelser: {program.exercises.length}
            </p>
            <p className="mt-2">{program.description}</p>
            <div className="w-96">
            <DetailView color="blue">
                <ExerciseTable exercises={program.exercises} color="blue" />
                <>{children}</>
            </DetailView>
            </div>
        </div>
    );
};
