import { getAuth } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postTarget } from "../../firebase/firestore/target/postTarget";
import { program } from "../../helpers/business/interfaces";
import { darkModeStyle } from "../../helpers/style/darkmode";

interface CreateTargetFormProps {
    programs: program[];
}

export const CreateTargetForm = ({ programs }: CreateTargetFormProps) => {
    const [targetIsValid, setTargetIsValid] = React.useState(true);
    const [description, setDescription] = useState("");

    const [values, setValues] = useState<{ [key: string]: number }>({});

    const auth = getAuth();
    const nav = useNavigate();

    const handleSubmit = () => {
        if (!auth.currentUser) return;

        if (!targetIsValid || description.length === 0) {
            alert("Du må skrive inn et mål");
            return;
        }

        postTarget(auth.currentUser.uid, description, values).then(() => {
            nav("/home");
        });
    };

    return (
        <>
            <div>
                <input
                    type="text"
                    placeholder="Treningsmål"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                    onFocus={() => setTargetIsValid(true)}
                    onBlur={(e) => {
                        setTargetIsValid(e.target.value.length > 0);
                    }}
                    className={`border-2 border-gray-300 p-2 rounded-lg w-full mt-4 ${darkModeStyle} ${
                        !targetIsValid && "border-red-500 bg-red-100"
                    } }`}
                />
            </div>
            <div>
                <p className="my-4 text-lg">
                    Velg program og antall økter du ønsker å trene i uken for å
                    oppnå målet ditt
                </p>
                {programs.map((program) => {
                    return (
                        <div key={program.name} className="my-4">
                            <div className="flex justify-between">
                                <div>
                                    <p className="font-medium text-lg">
                                        {program.name}
                                    </p>
                                    <p className="text-small font-light">
                                        {program.description}
                                    </p>
                                </div>
                                <input
                                    type="number"
                                    min={0}
                                    max={7}
                                    className="w-1/5 border-2 text-black px-2 py-1 text-right"
                                    placeholder="økter i uken"
                                    onChange={(e) => {
                                        if (
                                            !e.target.value ||
                                            parseInt(e.target.value) < 1
                                        )
                                            return;
                                        setValues({
                                            ...values,
                                            [program.name +
                                            "#" +
                                            program.owner]: Number(
                                                e.target.value
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
                <button
                    className="bg-orange-500 hover:bg-orange-700 text-white py-2 px-4 w-full rounded-full mt-8"
                    onClick={handleSubmit}
                >
                    Opprett mål
                </button>
            </div>
        </>
    );
};
