import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import Header from "../components/header";
import { getUser } from "../firebase/firestore/users/getUserData";
import React from "react";
import OtherUsersPrograms from "../components/program/otherUsersPrograms";
import { OtherUsersSessions } from "../components/program/otherUsersSessions";
import { user } from "../helpers/business/interfaces";
import { ProfileCard } from "../components/profile/profileCard";
import {
    getPrograms,
    programDB,
} from "../firebase/firestore/program/getPrograms";
import {
    getSessions,
    sessionDB,
} from "../firebase/firestore/sessions/getSessions";

export const UserPage = () => {
    const [userData, setUserData] = useState<user | null>(null);
    const [currentUser, setCurrentUser] = useState<user | null>(null);
    const [programs, setPrograms] = useState<programDB[]>([]);
    const [sessions, setSessions] = useState<sessionDB[]>([]);
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const auth = getAuth();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uid = urlParams.get("uid");

    useEffect(() => {
        if (uid && auth.currentUser) {
            getUser(uid).then((user) => {
                setUserData(user);
            });
            getUser(auth.currentUser.uid).then((user) => {
                setCurrentUser(user);
            });
            getPrograms(uid).then((programs) => {
                setPrograms(programs);
            });
            getSessions(uid).then((sessions) => {
                setSessions(sessions);
            });
        }
    }, [triggerUpdate, auth.currentUser]);

    const [showEither, toggleProgramSession] = useState(false);

    const onClick = () => {
        toggleProgramSession((prevState) => !prevState);
        setTriggerUpdate(!triggerUpdate);
    };

    return (
        <div className="mt-8">
            <Header
                text={`${userData?.firstname} ${userData?.lastname} sin profil`}
            />
            <ProfileCard userData={userData} />
            <div>
                <button
                    className="w-64 p-2 rounded-lg  text-white bg-blue-600 hover:bg-blue-500 border border-blue-600 dark:bg-transparent dark:text-blue-600 hover:dark:text-slate-800 hover:dark:bg-blue-600"
                    type="submit"
                    onClick={onClick}
                >
                    {showEither
                        ? "Vis treningsøkter"
                        : "Vis treningsprogrammer"}
                </button>
                {showEither && currentUser && userData && (
                    <OtherUsersPrograms
                        programs={programs}
                        currentUser={currentUser.UID}
                        followers={userData.followers}
                    />
                )}
                {!showEither && currentUser && userData && (
                    <OtherUsersSessions
                        sessions={sessions}
                        currentUser={currentUser.UID}
                        followers={userData.followers}
                    />
                )}
            </div>
        </div>
    );
};
