import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import { ProfileCard } from "../components/profile/profileCard";
import { TargetCard } from "../components/target/targetCard";
import { getUser } from "../firebase/firestore/users/getUserData";
import { user } from "../helpers/business/interfaces";

export const Profile = () => {
    const [userData, setUserData] = useState<user | null>(null);
    const [loading, setLoading] = useState(true);

    const auth = getAuth();
    useEffect(() => {
        if (auth.currentUser) {
            getUser(auth.currentUser.uid)
                .then((user) => {
                    setUserData(user);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [auth.currentUser]);

    return (
        <div className={`mt-8 ${loading && "hidden"}`}>
            <Header text={"Min profil"} />
            <ProfileCard userData={userData} />
            <TargetCard />
            <Link to={`/createTarget?uid=${auth.currentUser?.uid}`}>
                <button className="bg-orange-500 hover:bg-orange-400 text-white py-2 px-4 w-full rounded-full mt-4">
                    Sett ditt treningsmål
                </button>
            </Link>
        </div>
    );
};
