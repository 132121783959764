import Header from "../components/header";
import dumbbell from "../img/dumbbell.png";
import reward from "../img/reward.png";
import community from "../img/communities.png";
import about1Img from "../img/about1.png";
import about2Img from "../img/about2.png";

export const About = () => (
    <section>
        <div className="mt-8">
            <Header text={"Om oss"} />
        </div>
        <div className="my-4">
            <p>
                WO Share er en platform for å opprette og dele treningsprogram
                og treningsøkter. Du kan også se treningsprogram og
                treningsøkter som andre har laget. Opprett program, legg til
                øvelser og del med andre. Når du har laget et program kan du
                senere lagre til øktene hvor du har utført dette programmet.
                Dette vil gi deg en ryddig og motiverende plattform for å følge
                opp progresjonen i treningen din. Samtidig vil du kunne følge
                med på venner og andre brukere sine treningsøkter og program
            </p>
        </div>
        <img src={about1Img} alt="illustration" className="w-full" />
        <div className="my-4">
            <Header text={"Våre mål"} />
            <div className="flex justify-around my-4">
                <div className="flex flex-col text-center w-1/6">
                    <img
                        src={dumbbell}
                        alt="dumbbell"
                        className="w-full dark:invert"
                    />
                    <p>Forbedre fysisk helse</p>
                </div>
                <div className="flex flex-col text-center w-1/6">
                    <img
                        src={reward}
                        alt="reward"
                        className="w-full dark:invert"
                    />
                    <p>Øke brukernes motivasjon</p>
                </div>
                <div className="flex flex-col text-center w-1/6">
                    <img
                        src={community}
                        alt="community"
                        className="w-full dark:invert"
                    />
                    <p>Skape fellesskap</p>
                </div>
            </div>
        </div>
        <img src={about2Img} alt="om2" className="w-full" />
        <div className="mt-4">
            <Header text={"Funksjonaliteter"} />
            <p className="my-4">
                Appen inneholder en rekke funksjonaliteter for å motivere og
                hjelpe deg mot dine mål. Treningsprogram opprettes enkelt under
                siden Treningsprogram, og du kan videre føre inn data og holde
                oversikt over progresjon.
            </p>
            <p className="my-4">
                Det som gjør WO share spesielt er vårt sosiale aspekt. Dine
                opprettede treningsprogram kan deles med venner på plattformen,
                slik at man kan følge med på hverandres progresjon. Videre kan
                man interagere med innlegg/oppdateringer gjennom likes og
                kommentarer, og vi har stor tro på at dette skaper samhold og
                gjensidig motivasjon!
            </p>
        </div>
    </section>
);
