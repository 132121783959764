import { query, where, collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { user } from "../../../helpers/business/interfaces";

export const getUsers = async (UID: string) => {
    const res: user[] = [];

    const programs = collection(firestore, "users");
    const q = query(programs, where("UID", "!=", UID));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        res.push(doc.data() as user);
    });
    return res;
};
