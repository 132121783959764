import { getAuth } from "firebase/auth";
import { sessionDB } from "../../firebase/firestore/sessions/getSessions";

interface GetStreakProps {
    sessions: sessionDB[]
}

const GetStreak = ({sessions}:GetStreakProps) => {
    const auth = getAuth();

    const msToDays = (n: number) => {
        return Math.floor(n / 86400000);
    };

    const today = new Date();
    const todayValue = msToDays(today.valueOf());

    const sessionsSorted = sessions.sort(
        (o1, o2) => o2.startTime.getTime() - o1.startTime.getTime()
    );

    const datesArray: number[] = [];

    sessionsSorted.forEach((s) => {
        datesArray.push(msToDays(s.startTime.valueOf()));
    });

    let streak = 0;

    if (datesArray[0] === todayValue) {
        streak += 1;
    }

    for (let i = 0; i < datesArray.length; i++) {
        const d0 = datesArray[i];
        const d1 = datesArray[i + 1];
        const dif = d0 - d1;
        if (dif > 1) {
            break;
        } else if (dif === 1) {
            streak += 1;
        }
    }


    return ( 
        <span>
            {streak.toString()}{streak > 0 ? "🔥" : ""}
        </span>
    );
};

export default GetStreak;
