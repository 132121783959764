import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import Header from "../components/header";
import { CreateTargetForm } from "../components/target/createTargetForm";
import {
    getPrograms,
    programDB,
} from "../firebase/firestore/program/getPrograms";

export const CreateTarget = () => {
    const [programs, setPrograms] = useState<programDB[]>([]);

    const auth = getAuth();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uid = urlParams.get("uid");

    useEffect(() => {
        if (uid && auth.currentUser) {
            getPrograms(uid).then((programs) => {
                setPrograms(programs);
            });
        }
    }, [auth.currentUser]);

    return (
        <div className="mt-8">
            <Header text={"Opprett mål"} />
            <CreateTargetForm programs={programs} />
        </div>
    );
};
