import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import Header from "../components/header";
import NavButton from "../components/navButton";
import { SessionCard } from "../components/program/sessionCard";
import {
    getSessions,
    sessionDB,
} from "../firebase/firestore/sessions/getSessions";

export const Sessions = () => {
    const [sessions, setSessions] = useState<sessionDB[]>([]);

    const [loading, setLoading] = useState(true);

    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser) {
            getSessions(auth.currentUser.uid)
                .then((sessions) => {
                    setSessions(sessions);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [auth.currentUser]);

    return (
        <div className={`mt-8 ${loading && "hidden"}`}>
            <div className="flex justify-between">
                <Header text={"Mine økter"} />
                <NavButton
                    text={"Registrer treningsøkt"}
                    url={"/createSession"}
                />
            </div>
            {sessions
                .sort(
                    (o1, o2) => o2.startTime.getTime() - o1.startTime.getTime()
                )
                .map((session) => {
                    return (
                        <ul
                            key={
                                session.programName +
                                session.startTime.toISOString() +
                                session.owner
                            }
                        >
                            <SessionCard session={session} />
                        </ul>
                    );
                })}
            {sessions.length == 0 && (
                <p className="italic mt-4">Ingen registrerte treningsøkter</p>
            )}
        </div>
    );
};
