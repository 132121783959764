type HeaderProps = {
    text: string;
};

function Header({ text }: HeaderProps) {
    return (
        <h1 className="text-3xl font-medium underline decoration-orange-400 dark:text-slate-200">
            {text}
        </h1>
    );
}

export default Header;
