import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { IntervalChart } from "../components/chart/chart";
import { ChartSelect } from "../components/chart/chartSelect";
import Header from "../components/header";
import NavButton from "../components/navButton";
import { ProgramCard } from "../components/program/programCard";
import {
    getPrograms,
    programDB,
} from "../firebase/firestore/program/getPrograms";
import {
    getSessions,
    sessionDB,
} from "../firebase/firestore/sessions/getSessions";
import {
    groupByInterval,
    sessionCountData,
} from "../helpers/stats/sessionCount";

export const Programs = () => {
    const [programs, setPrograms] = useState<programDB[]>([]);
    const [sessions, setSessions] = useState<sessionDB[]>([]);

    const [loading, setLoading] = useState(true);

    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser) {
            getPrograms(auth.currentUser.uid)
                .then((programs) => {
                    setPrograms(programs);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            getSessions(auth.currentUser.uid)
                .then((sessions) => {
                    setSessions(sessions);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [auth.currentUser]);

    return (
        <div className={`mt-8 ${loading && "hidden"}`}>
            <div className="flex justify-between">
                <Header text={"Mine program"} />
                <NavButton
                    text={"Opprett program"}
                    url={"/createWorkoutProgram"}
                />
            </div>
            {programs.map((program) => {
                return (
                    <ul key={program.name}>
                        <ProgramCard program={program}>
                            <ChartSelect
                                sessions={sessions}
                                program={program}
                                className="w-max"
                            />
                        </ProgramCard>
                    </ul>
                );
            })}
            {programs.length == 0 && !loading && (
                <p className="italic mt-4">Ingen registrerte programmer</p>
            )}
        </div>
    );
};
