import { user } from "../../helpers/business/interfaces";

interface FollowersProps {
    userData?: user | null;
}

export const Follwers = ({ userData }: FollowersProps) => (
    <>
        {userData && (
            <div className="mt-4 w-1/4">
                <p className="mb-2">
                    <span className=" font-bold">Følgere: </span>
                    {userData.followers.length}
                </p>
                <p className="my-2">
                    <span className=" font-bold">Følger: </span>
                    {userData.following.length}
                </p>
            </div>
        )}
        {!userData && <p className="italic">Ingen bruker funnet</p>}
    </>
);
