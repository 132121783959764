import { ExercisesForm } from "../components/program/ExercisesForm";
import { program, exercise } from "../helpers/business/interfaces";
import { ProgramForm } from "../components/program/programForm";
import { getAuth } from "firebase/auth";
import { postProgram } from "../firebase/firestore/program/programDB";
import Header from "../components/header";
import NavButton from "../components/navButton";
import SubHeader from "../components/subHeader";
import { useNavigate } from "react-router-dom";

export const CreateWorkoutProgram = () => {
    let exercises: exercise[] = [];
    let program: program;

    const navigate = useNavigate();

    const auth = getAuth();
    function handleCreateWorkoutProgram() {
        try {
            validateProgram();
            validateExercises();
        } catch (e) {
            alert(e);
            return;
        }

        program.exercises = exercises;

        try {
            if (auth.currentUser) {
                postProgram(auth.currentUser.uid, program).then(() => {
                    navigate("/programs");
                });
            } else {
                throw new Error("Du må være logget inn");
            }
        } catch (e) {
            alert(e);
        }
    }

    const validateProgram = () => {
        if (program === undefined)
            throw new Error("Programmet er tomt, fyll inn verdier");

        Object.values(program).map((value: string | number) => {
            if (Number.isInteger(value)) {
                if (value === 0) throw new Error("Velg verdi for alle felt");
            }

            if (value === undefined || value === "") {
                //if any of the values are undefined or empty, dont bother querying the DB.
                throw new Error("Program er ikke fullført");
            }
        });
    };

    const validateExercises = () => {
        if (exercises === undefined || exercises.length === 0)
            throw new Error("Ingen øvelse lagt til, fyll inn øvelser");
        exercises.map((exercise) => {
            Object.values(exercise).map((value: string | number) => {
                if (value === undefined || value === "") {
                    //if any of the values are undefined or empty, dont bother querying the DB.
                    throw new Error("Øvelse er ikke fulført");
                }
            });
        });
    };

    return (
        <div className="mt-8">
            <div className="flex justify-between mb-4">
                <Header text="Opprett treningsprogram" />
                <NavButton text={"Tilbake"} url={"/programs"} />
            </div>
            <ProgramForm sendChange={(newProgram) => (program = newProgram)} />
            {/*Skjema for å legge til styrkeøvelser (bør gjøres mer generell i fremtiden...)*/}
            <div className="mt-4">
                <SubHeader text="Legg til øvelser" />
                <div className="max-h-72 overflow-auto">
                    <ExercisesForm
                        sendChange={(newExercises) =>
                            (exercises = newExercises)
                        }
                    />
                </div>
                <button
                    className="w-full mt-4 mb-16 bg-blue-500 hover:bg-blue-400 text-white p-2 rounded-full"
                    onClick={handleCreateWorkoutProgram}
                >
                    Opprett treningsprogram
                </button>
            </div>
        </div>
    );
};
