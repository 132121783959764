import { useState } from "react";
import { program, emptyProgram } from "../../helpers/business/interfaces";
import { darkModeStyle } from "../../helpers/style/darkmode";
import { VisibilitySelection } from "./programVisibility";

const regexes = {
    name: /^[a-zA-ZæøåÆØÅ0-9 ]{3,}$/,
    category: /^[a-zA-ZæøåÆØÅ0-9 ]{3,}$/,
    description: /^[a-zA-ZæøåÆØÅ0-9,-\\. ]{5,}$/,
    duration: /^[1-9][0-9]*$/,
    intensity: /^[1-5]$/,
    exercises: /.*/,
    owner: /^[a-zA-ZæøåÆØÅ0-9 ]{3,}$/,
    visibility: /private|public|followers/,
    dateCreated: /.*/,
};

type program_keys = keyof program;

export const ProgramForm = ({
    sendChange,
}: {
    sendChange: (e: program) => void;
}) => {
    const [program, setProgram] = useState(() => emptyProgram());
    const [nameIsValid, setNameIsValid] = useState(true);
    const [categoryIsValid, setCategoryIsValid] = useState(true);
    const [descriptionIsValid, setDescriptionIsValid] = useState(true);

    function handleChange(key: program_keys, changed: string) {
        let val: string | number = changed;
        if (key === "duration" || key === "intensity") {
            val = parseInt(val);
        }

        const newProgram: program = { ...program, [key]: val };

        setProgram(newProgram);
        sendChange(newProgram);
    }

    function validateValue(key: program_keys, value: string): boolean {
        return regexes[key].test(value);
    }

    return (
        <div className="flex-col">
            <VisibilitySelection
                value={program.visibility ? program.visibility : "private"}
                onChange={(e) => handleChange("visibility", e)}
            />
            <input
                type="text"
                placeholder="Navn"
                value={program.name}
                onChange={(e) => handleChange("name", e.target.value)}
                onBlur={() =>
                    setNameIsValid(validateValue("name", program.name))
                }
                className={`border-2 border-gray-300 p-2 rounded-lg w-full mt-4 ${darkModeStyle} ${
                    !nameIsValid && "border-red-500 bg-red-100"
                } }`}
            />
            <input
                type="text"
                placeholder="Kategori"
                value={program.category}
                onChange={(e) => handleChange("category", e.target.value)}
                onBlur={() =>
                    setCategoryIsValid(
                        validateValue("category", program.category)
                    )
                }
                onFocus={() => setCategoryIsValid(true)}
                className={`border-2 border-gray-300 p-2 rounded-lg w-full mt-4 ${
                    !categoryIsValid && "border-red-500 bg-red-100"
                } ${darkModeStyle}}`}
            />
            <input
                type="text"
                placeholder="Beskrivelse"
                value={program.description}
                onChange={(e) => handleChange("description", e.target.value)}
                onBlur={() =>
                    setDescriptionIsValid(
                        validateValue("description", program.description)
                    )
                }
                onFocus={() => setDescriptionIsValid(true)}
                className={`border-2 border-gray-300 p-2 rounded-lg w-full mt-4 ${
                    !descriptionIsValid && "border-red-500 bg-red-100"
                } ${darkModeStyle}`}
            />
            <select
                value={program.duration}
                onChange={(e) => handleChange("duration", e.target.value)}
                className={`border-2 p-2 border-gray-300 rounded-lg w-full mt-4 placeholder-gray-300 ${darkModeStyle}`}
            >
                <option value="0" disabled>
                    Varighet
                </option>
                <option value="15">15 min</option>
                <option value="30">30 min</option>
                <option value="60">1 time</option>
                <option value="90">1.5 timer</option>
                <option value="120">2 timer</option>
                <option value="150">2.5 timer</option>
                <option value="180">3 timer</option>
            </select>
            <select
                value={program.intensity}
                onChange={(e) => handleChange("intensity", e.target.value)}
                className={`border-2 p-2 border-gray-300 rounded-lg w-full mt-4 placeholder-gray-300 ${darkModeStyle}`}
            >
                <option value="0" disabled>
                    Intensitet
                </option>
                <option value="1">Svært lav</option>
                <option value="2">Lav</option>
                <option value="3">Middels</option>
                <option value="4">Høy</option>
                <option value="5">Svært høy</option>
            </select>
        </div>
    );
};
