import { Link } from "react-router-dom";

type NavButtonProps = {
    text: string;
    url: string;
};

function NavButton({ text, url }: NavButtonProps) {
    return (
        <Link to={url}>
            <button className="w-48 p-2 rounded-lg  text-white bg-blue-600 hover:bg-blue-500 border border-blue-600 dark:bg-transparent dark:text-blue-600 hover:dark:text-slate-800 hover:dark:bg-blue-600">
                {text}
            </button>
        </Link>
    );
}

export default NavButton;
