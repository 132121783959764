import {
    query,
    where,
    collection,
    getDocs,
    Timestamp,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { session } from "../../../helpers/business/interfaces";
import { postSession } from "./postSession";

export interface sessionDB extends session {
    owner: string;
}

export const getSessionsFromUsers = async (
    UIDs: string[],
    setSessions: React.Dispatch<React.SetStateAction<session[]>>
) => {
    // Get documents from collection "programs" where the user is a member of the document ID
    const res: sessionDB[] = [];

    const sessions = collection(firestore, "session");

    await UIDs.forEach(async (UID) => {
        const q = query(sessions, where("owner", "==", UID));
        return await getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data() as sessionDB;
                if (data === undefined) {
                    return;
                }
                data.startTime = new Date(
                    (data.startTime as unknown as Timestamp).seconds * 1000
                );
                data.endTime = new Date(
                    (data.endTime as unknown as Timestamp).seconds * 1000
                );
                data.dateCreated = new Date(
                    (data.dateCreated as unknown as Timestamp).seconds * 1000
                );
                res.push(data);

                res.sort((a, b) => {
                    return b.startTime.getTime() - a.startTime.getTime();
                });

                setSessions([...res]);
            });
        });
    });
};
