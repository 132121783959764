import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTarget } from "../../firebase/firestore/target/getTarget";
import SubHeader from "../subHeader";

import { target } from "../../helpers/business/interfaces";
import { ProgramCard } from "./programCard";

export const TargetCard = () => {
    const auth = getAuth();
    const [target, setTarget] = useState<target | null>(null);
    const [show, setShow] = useState(true);

    const toggleShow = () => {
        setShow(!show);
    };

    useEffect(() => {
        if (auth.currentUser) {
            getTarget(auth.currentUser.uid).then((target) => {
                setTarget(target);
            });
        }
    }, [auth.currentUser]);

    const createTargetCard = () => {
        if (!target) return;
        const { programs } = target;
        const res = [];
        for (const program in programs) {
            const programCount = programs[program];
            res.push(
                <ProgramCard
                    programname={program}
                    target={programCount}
                    key={program}
                />
            );
        }
        return res;
    };

    return (
        <>
            <div className="flex-block w-full ">
                <div className="flex justify-between">
                    <SubHeader text={"Status - mitt treningsmål"} />
                    <button className="hover:underline" onClick={toggleShow}>
                        {show ? "Skjul" : "Vis"}
                    </button>
                </div>
                <div className={`${!show && "hidden"}`}>
                    <div className="flex justify-between mt-4 shadow-lg p-8 border-l-8 border-orange-400 bg-white dark:bg-slate-800 dark:shadow-slate-500 dark:shadow-sm">
                        {target && (
                            <p className="italic">{target.description}</p>
                        )}
                    </div>

                    <p className="mt-8 pl-2 font-medium">
                        {target && Object.keys(target.programs).length > 0
                            ? "Programmene som er en del av målet ditt:"
                            : "Du har ikke opprettet noen økter som del av målet ditt. Opprett nytt treningsmål for å få en oversikt over økter."}
                    </p>
                    <div className="my-4 flex flex-wrap">
                        {createTargetCard()}
                    </div>
                    <Link to="/createSession">
                        <button className="border border-orange-500 text-orange-500 p-2 rounded-full w-full my-2 hover:underline hover:shadow-md">
                            Registrer ny økt
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
};
