import { useState } from "react";
import { exercise } from "../../helpers/business/interfaces";
import { darkModeStyle } from "../../helpers/style/darkmode";

let curID = 0;

export function ExercisesForm({
    sendChange,
}: {
    sendChange: (e: exercise[]) => void;
}) {
    /**
     * Input form for multiple exercises.
     */
    const [exercises, setExercises] = useState<exercise[]>([]);
    const [lastExercise, setLastExercise] = useState<exercise>({
        label: "",
        sets: "",
        reps: "",
        id: -1,
    });
    // lastExercise is the exercise which has not yet been added to the list of exercises!
    // It is shown last in the view.

    function handleAdd(e: exercise) {
        if (e.reps === "" || e.sets === "" || e.label === "")
            throw new Error("Empty exercise");
        e.id = ++curID;

        const ls = [...exercises, e];

        setExercises(ls);
        setLastExercise({ label: "", sets: "", reps: "", id: -1 });

        sendChange(ls);
    }

    function handleChange(updated: exercise) {
        let found = false;

        if (!/^[1-9][0-9]*$/.test(updated.reps)) {
            updated.reps = updated.reps.slice(0, -1);
        }
        if (!/^[1-9][0-9]*$/.test(updated.sets)) {
            updated.sets = updated.sets.slice(0, -1);
        }

        const ls = exercises.map((old) => {
            if (old.id === updated.id) {
                found = true;
                return updated;
            }
            return old;
        });

        if (!found) {
            setLastExercise(updated);
        } else {
            setExercises(ls);
        }

        sendChange(ls);
    }

    function handleRemove(e: exercise) {
        const ls = exercises.filter((old) => old.id !== e.id);

        setExercises(ls);
        sendChange(ls);
    }

    return (
        <ul>
            {exercises.map((e) => (
                <li key={e.id}>
                    <ExerciseForm
                        data={e}
                        onAdd={handleAdd}
                        onChange={handleChange}
                        onRemove={handleRemove}
                    />
                </li>
            ))}

            <li key="not-yet-added-to-exercises-array">
                <ExerciseForm
                    data={lastExercise}
                    onAdd={handleAdd}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    addButton={true}
                />
            </li>
        </ul>
    );
}

function ExerciseForm({
    data,
    onAdd,
    onChange,
    onRemove,
    addButton = false,
}: {
    data: exercise;
    onAdd: (an: exercise) => void;
    onChange: (an: exercise) => void;
    onRemove: (an: exercise) => void;
    addButton?: boolean;
}) {
    /**
     * Input form for a single exercise. Supposed to be placed in an ExercisesForm.
     */
    let changeButton;

    const changeButtonStyle =
        "w-1/4 bg-transparent hover:bg-blue-500 text-blue-500 hover:text-white py-2 px-4 border-2 border-blue-500 hover:border-transparent rounded-lg hover:dark:text-slate-800";

    const [nameIsValid, setNameIsValid] = useState(true);
    const [setsIsValid, setSetsIsValid] = useState(true);
    const [repsIsValid, setRepsIsValid] = useState(true);

    const handleAdd = () => {
        try {
            onAdd(data);
        } catch (e) {
            setNameIsValid(validateName(data.label));
            setSetsIsValid(validateNum(data.sets));
            setRepsIsValid(validateNum(data.reps));
        }
    };

    const validateName = (name: string): boolean => {
        if (name.length > 2) {
            return true;
        }
        return false;
    };

    const validateNum = (num: string): boolean => {
        if (num === "") {
            return false;
        }
        const numInt = parseInt(num);
        if (numInt > 0) {
            return true;
        }
        if (numInt <= 0 || num === "") {
            return false;
        }
        return false;
    };

    if (addButton) {
        changeButton = (
            <button
                className={changeButtonStyle}
                onClick={() => {
                    handleAdd();
                }}
            >
                Legg til
            </button>
        );
    } else {
        changeButton = (
            <button
                className={changeButtonStyle}
                onClick={() => {
                    onRemove(data);
                }}
            >
                Fjern
            </button>
        );
    }

    return (
        <div className="flex gap-4 my-4">
            <input
                type="text"
                placeholder="Navn på øvelse"
                value={data.label}
                onChange={(e) => onChange({ ...data, label: e.target.value })}
                onBlur={(e) => setNameIsValid(validateName(e.target.value))}
                className={`w-full border-2 border-gray-300 p-2 rounded-lg ${
                    !nameIsValid && "border-red-500 bg-red-100"
                } ${darkModeStyle}`}
            />
            <input
                type="text"
                placeholder="Sets"
                value={data.sets}
                onChange={(e) => onChange({ ...data, sets: e.target.value })}
                onBlur={(e) => setSetsIsValid(validateNum(e.target.value))}
                className={`w-1/4 border-2 border-gray-300 p-2 rounded-lg ${
                    !setsIsValid && "border-red-500 bg-red-100"
                } ${darkModeStyle}`}
            />
            <input
                type="text"
                placeholder="Reps"
                value={data.reps}
                onChange={(e) => onChange({ ...data, reps: e.target.value })}
                onBlur={(e) => setRepsIsValid(validateNum(e.target.value))}
                className={`w-1/4 border-2 border-gray-300 p-2 rounded-lg ${
                    !repsIsValid && "border-red-500 bg-red-100"
                } ${darkModeStyle}`}
            />
            {changeButton}
        </div>
    );
}
