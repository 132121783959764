import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";

const targetDB = collection(firestore, "target");
// This is just a helper to add data to the firestore database

export const postTarget = async (
    UID: string,
    description: string,
    targets: object
) => {
    await setDoc(doc(targetDB, UID), {
        description: description,
        programs: targets,
        owner: UID,
    });
};
