import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { unFollowUser } from "../../firebase/firestore/users/following/unFollowUser";
import { getUser } from "../../firebase/firestore/users/getUserData";
import { user } from "../../helpers/business/interfaces";
import profileImg from "../../img/profilbilde.jpeg";

interface UserCardProps {
    uid: string;
    currentUser: user;
    enableUnfollow: boolean;
    triggerUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserCard = ({
    uid,
    currentUser,
    enableUnfollow,
    triggerUpdate,
}: UserCardProps) => {
    const [user, setUser] = useState<user | null>(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        getUser(uid)
            .then((user) => {
                setUser(user);
            })
            .catch(() => {
                setError(true);
            });
    }, [currentUser]);

    const handleUnfollow = () => {
        if (user && currentUser) unFollowUser(user.UID, currentUser);
        triggerUpdate((prev) => !prev);
    };

    return (
        <div className=" p-4">
            {user && (
                <div className="flex justify-between">
                    <div className="flex">
                        <img
                            className="w-12 h-12 rounded-full"
                            src={profileImg}
                            alt="user"
                        />
                        <div className="ml-4">
                            <Link to={`/user?uid=${user.UID}`}>
                                <p className="font-bold hover:underline">
                                    {user.firstname} {user.lastname}
                                </p>
                            </Link>
                            <p className="text-gray-500">{user.username}</p>
                        </div>
                    </div>
                    {enableUnfollow && (
                        <div className="flex">
                            <button
                                className="bg-red-400 text-white py-1 px-4 rounded-full border border-red-400 hover:bg-red-600 w-32 dark:bg-transparent dark:text-red-400 dark:hover:bg-red-400 dark:hover:text-slate-700"
                                onClick={handleUnfollow}
                            >
                                Slutt å følge
                            </button>
                        </div>
                    )}
                </div>
            )}
            {error && <p className="italic">Feil ved innlasting av bruker</p>}
        </div>
    );
};
