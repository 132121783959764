import { useEffect, useState } from "react";
import Header from "../header";
import {
    program,
    exercise,
    completedExercise,
    session,
    visibility,
} from "../../helpers/business/interfaces";
import SubHeader from "../subHeader";
import { getAuth } from "firebase/auth";
import {
    programDB,
    getPrograms,
} from "../../firebase/firestore/program/getPrograms";
import { UTCDateToInput, UTCInputToDate } from "../../helpers/date/inputdate";
import { stringsToInts } from "../../helpers/arrays";
import { postSession } from "../../firebase/firestore/sessions/postSession";
import NavButton from "../navButton";
import { darkModeStyle } from "../../helpers/style/darkmode";
import { VisibilitySelection } from "./programVisibility";
import { useNavigate } from "react-router-dom";

/**
 * Denne komponenten tar inn et {@link program program-objekt} og gir så muligheten for å føre inn en ny treningsøkt (`session`). `sendChange` blir kallt hver gang en endring skjer i skjemaet, slik at eksterne komponenter kan følge med på tilstanden i skjemaet, og evt. sende tilstanden til database ved knappetrykk.
 * All data lagres i et {@link session session-objekt}.
 *
 * Meningen er at brukeren skal kunne navigere/søke i programmer, velge ønsket program, og så lage ny økt for dette programmet. Da konstrueres denne komponenten (SessionForm) med gitt program, og brukeren får et skjema som kan fylles inn for økten.
 *
 */

export default function SessionForm() {
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [description, setDescription] = useState("");
    const [program, setProgram] = useState<program>();
    const [completedExercises, setCompletedExercises] = useState<
        completedExercise[]
    >([]);
    const [visibility, setVisibility] = useState<visibility>("private");

    const [programs, setPrograms] = useState<programDB[]>([]);
    const auth = getAuth(); // Mulig bug: denne blir kalt hver gang komponenten oppdaterer seg (bruk helst en setstate!)

    const navigate = useNavigate();

    useEffect(() => {
        const initDate = UTCDateToInput(new Date());
        setStart(initDate);
        setEnd(initDate);
    }, [auth.currentUser]);

    useEffect(() => {
        // TODO: Programmer blir ikke lastet inn igjen dersom brukeren gjør CTRL-R på nettsiden
        if (auth.currentUser) {
            getPrograms(auth.currentUser.uid).then((programs) => {
                setPrograms(programs);
            });
        }
    }, [auth.currentUser]);

    const handleCreateSession = () => {
        if (program === undefined || program === null) {
            alert("Du må velge et program");
            return;
        }
        if (UTCInputToDate(start).getTime() > UTCInputToDate(end).getTime()) {
            alert("Vennligst påse at start-tidspunkt er før slutt-tidspunkt");
            return;
        }

        const session: session = {
            programName: program.name,
            description: description,
            startTime: UTCInputToDate(start),
            endTime: UTCInputToDate(end),
            completedExercises: completedExercises,
            visibility: visibility,
            dateCreated: new Date(),
        };

        try {
            if (auth.currentUser) {
                postSession(auth.currentUser.uid, session).then(() => {
                    navigate("/sessions");
                });
            } else {
                throw new Error("Feil ved opplastning av program");
            }
        } catch (e) {
            alert(e);
        }
    };

    const handleStartDate = (e: string) => {
        const newStartDate = UTCInputToDate(e);
        const startDate = UTCInputToDate(start);
        const endDate = UTCInputToDate(end);

        // If new start date is invalid, keep the old start date.
        if (newStartDate.toString() === "Invalid Date") {
            console.log("New start date is invalid, something is fishy...");
            return;
        }
        if (newStartDate.getTime() > new Date().getTime()) {
            // New start date was later than right now.
            const cur = UTCDateToInput(new Date());
            setStart(cur);
            setEnd(cur);
            return;
        }

        // If end date is invalid, set both start date and end date to the new start date.
        if (endDate.toString() === "Invalid Date") {
            setStart(e);
            setEnd(e);
            return;
        }

        // If the new start date is greater than end date, reset end date so that the time interval between new start date and end date
        // matches the previous time interval between previous start date and end date if possible, otherwise set end date equal to the new start date.
        // Then set start date to the new start date.
        if (newStartDate.getTime() > endDate.getTime()) {
            if (
                startDate.toString() !== "Invalid Date" &&
                startDate.getTime() < endDate.getTime()
            ) {
                const diff = endDate.getTime() - startDate.getTime();
                endDate.setTime(newStartDate.getTime() + diff);
                setEnd(UTCDateToInput(endDate));
                setStart(UTCDateToInput(newStartDate));
            } else {
                setEnd(UTCDateToInput(newStartDate));
                setStart(UTCDateToInput(newStartDate));
            }
            return;
        }

        setStart(UTCDateToInput(newStartDate));
    };

    const handleEndDate = (e: string) => {
        const startDate = UTCInputToDate(start);
        const endDate = UTCInputToDate(end);
        const newEndDate = UTCInputToDate(e);

        if (newEndDate.toString() === "Invalid Date") {
            return;
        }
        if (newEndDate.getTime() > new Date().getTime()) {
            // New end date was later than right now.
            setEnd(UTCDateToInput(new Date()));
            return;
        }

        // Only set end date if it is greter than or equal to start date.
        if (startDate.toString() === "Invalid Date") {
            setEnd(e);
            setStart(e);
            return;
        }

        // If the new end date is less than start date, reset start date so that the time interval between start date and new end date
        // matches the previous time interval between previous start date and end date if possible, otherwise set start date equal to the new end date.
        // Then set end date to the new end date.
        if (newEndDate.getTime() < startDate.getTime()) {
            if (
                endDate.toString() !== "Invalid Date" &&
                startDate.getTime() < endDate.getTime()
            ) {
                const diff = endDate.getTime() - startDate.getTime();
                startDate.setTime(newEndDate.getTime() - diff);
                setEnd(UTCDateToInput(newEndDate));
                setStart(UTCDateToInput(startDate));
            } else {
                setEnd(UTCDateToInput(newEndDate));
                setStart(UTCDateToInput(newEndDate));
            }
            return;
        }

        setEnd(UTCDateToInput(newEndDate));
    };

    return (
        <section className="flex flex-col gap-2 my-8">
            {/*
            Tenker måten å velge program på går via program-oversikten (kan eventuelt lage
            en søkefunksjon som filtrerer program via kategori/tittel). Venter på at program-oversikten
            er merget inn i main for dette! 
            (I mens bruker jeg kun et eksempel program)
            men tanken er altså at funksjons-kallet til denne komponenten blir
            
            <SessionForm
                program={...}
                sendChange=(session => ...)
            />
        */}

            {/*Tid*/}
            <div className="flex justify-between">
                <Header text="Opprett treningsøkt" />
                <NavButton text={"Tilbake"} url={"/sessions"}></NavButton>
            </div>
            <p className="mt-2">
                Her kan du opprette en ny treningsøkt. Velg programmet du har
                trent fra listen. Kommenter gjerne økten din med en beskrivelse,
                og fyll inn antall kg du har løftet for hvert sett per øvelse.
            </p>
            <VisibilitySelection
                value={visibility}
                onChange={(e) => setVisibility(e)}
            />

            <div className="my-2">
                <SubHeader text="Program:" />

                <select
                    className={`border-2 p-2 border-gray-300 rounded-lg w-full mt-4 placeholder-gray-300 ${darkModeStyle}`}
                    defaultValue={""}
                    onChange={(e) => {
                        const newProgram = programs.find(
                            (p) => p.name === e.target.value
                        );
                        if (newProgram !== program) {
                            setCompletedExercises([]); // different program means different exercises. So clear this array.
                        }
                        setProgram(newProgram);
                    }}
                >
                    <option value="" disabled>
                        {" "}
                        -- Velg program --{" "}
                    </option>
                    {programs.map((program) => {
                        return (
                            <option
                                key={program.owner + program.name}
                                value={program.name}
                            >
                                {program.name}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="my-2">
                <SubHeader text="Tid:" />
                <div className="my-4">
                    <div className="flex flex-row my-2">
                        <p className="grow">Start-tidspunkt:</p>
                        <input
                            type="datetime-local"
                            placeholder="Start-tid"
                            value={start}
                            onChange={(e) => handleStartDate(e.target.value)}
                            className={`border-2 rounded-lg p-2 ${darkModeStyle}`}
                        />
                    </div>
                    <div className="flex flex-row my-2">
                        <p className="grow">Slutt-tidspunkt:</p>
                        <input
                            type="datetime-local"
                            placeholder="Slutt-tid"
                            value={end}
                            onChange={(e) => handleEndDate(e.target.value)}
                            className={`border-2 rounded-lg p-2 ${darkModeStyle}`}
                        />
                    </div>
                </div>
                <div className="w-full my-4">
                    <SubHeader text="Kommentar:" />
                    <textarea
                        className={`border-2 p-2 border-gray-300 rounded-lg min-h-[2em] max-h-56 outline-none w-full my-4 ${darkModeStyle}`}
                        placeholder="Dette var en fantastisk økt!"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className="my-2">
                        {/*Exercises*/}
                        <SubHeader text="Utførte øvelser:" />
                        {!program && (
                            <p className="italic mt-2">
                                Velg program fra menyen øverst på siden for å
                                fylle inn for øvelser.
                            </p>
                        )}
                        {program &&
                            program.exercises.map((e, i) => (
                                <div
                                    key={program.name + i.toString()}
                                    className="my-4"
                                >
                                    <CompletedExerciseForm
                                        exercise={e}
                                        unit="kg"
                                        onChange={(e) => {
                                            completedExercises[i] = e;
                                        }}
                                    />
                                </div>
                            ))}
                        {program && (
                            <button
                                className="w-full mt-4 mb-16 bg-orange-500 hover:bg-orange-400 text-white p-2 rounded-full"
                                onClick={handleCreateSession}
                            >
                                Registrer treningsøkt
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

/**
 * `exercise` - exercise object to fill in data for,
 * `unit` - unit of the data,
 * `onChange` - function with a completedExercise (containing session-data for an exercise) as argument.
 *                   This component calls this function with the current data as argument whenever a change occurs.
 *  Returns a row component of title + input fields for the exercise-argument. This is the inputs required for one exercise of the program.
 */
function CompletedExerciseForm({
    exercise,
    unit,
    onChange,
}: {
    exercise: exercise;
    unit: string;
    onChange: (a: completedExercise) => void;
}) {
    //Denne sjekken kan fjernes når `sets` får tallverdi i exercise interfacet!
    const setCount = parseInt(exercise.sets);
    if (setCount === undefined) {
        return <></>;
    }

    const [sessionValues, setSessionValues] = useState<string[]>(() =>
        new Array(setCount).fill("")
    );

    // Initially deliver to the creator of this component a default completedExercise,
    // since we do allow empty input fields for each exercise set.
    // Empty input fields are interpreted as 0.
    useEffect(() => {
        onChange({ ...exercise, sessionValues: new Array(setCount).fill(0) });
    }, []);

    function changeSessionValues(val: string, i: number) {
        if (!/^([1-9][0-9]*|0?)$/.test(val)) {
            return;
        }

        const ls: string[] = Array(...sessionValues);
        ls[i] = val;
        setSessionValues(ls);
        onChange({ ...exercise, sessionValues: stringsToInts(ls) });
    }

    // Creates input fields for the exercise-argument. One input-field per set in the exercise (setCount).
    // Returns a row of title + input fields for the exercise-argument. This is the
    // inputs required for one exercise of the program.
    return (
        <div className="flex flex-row items-end w-full content-center border-t-2 border-gray-200">
            <div className="w-1/4 my-2">
                <p>{exercise.label}</p>
                <p>{`${exercise.sets.toString()}x${exercise.reps.toString()}`}</p>
            </div>
            <div className="w-3/4 my-2">
                <div className="flex flex-row gap-2">
                    {sessionValues.map((_, i) => {
                        const tooltip = `${unit} i sett ${i + 1}`;
                        return (
                            <div
                                key={
                                    exercise.sets +
                                    exercise.label +
                                    i.toString()
                                }
                                className="w-full"
                            >
                                <p
                                    className={`${
                                        sessionValues[i] === ""
                                            ? "invisible"
                                            : "visible"
                                    } transition-opacity ease-in-out text-xs text-gray-400`}
                                >
                                    {tooltip}
                                </p>
                                <input
                                    className={`border-2 rounded-lg p-1 w-full border-gray-300 ${darkModeStyle}`}
                                    type="text"
                                    placeholder={tooltip}
                                    value={
                                        sessionValues[
                                            i
                                        ] /*I fremtiden kan vi la sessionValues være en array med verdier fra forrige treningsøkt!*/
                                    }
                                    onChange={(e) =>
                                        changeSessionValues(e.target.value, i)
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
