import { chartData } from "../../components/chart/chart";
import { completedExercise, program, session } from "../business/interfaces";

export type interval =  "week"|"month"|"year";

export interface sessionGroup {
    start: Date,
    end: Date,
    sessions: session[]
}

interface exerciseGroup {
    start: Date,
    end: Date,
    exercises: completedExercise[]
}

export function sessionCountData(groups: sessionGroup[], program: program) {
    // You can use this function as a template for how to create new statistics based on intervalgroups
    const programName = program.name;
    return groups.map((g) => {
        const y = g.sessions.reduce((count: number, session) => {
            if (session.programName === programName) {
                return count + 1;
            }
            return count;
        }, 0)
        return {x: g.start, y: y};
    })
}

export function sessionMaxWeights(groups: sessionGroup[], program: program) {
    const datasets = new Map<string, chartData[]>();
    const exgroups = new Map<string, exerciseGroup[]>();
    const programName = program.name
    // Kneading the input parameters to be of workable format
    groups.forEach((g) => {
        const exercises = new Map<string, completedExercise[]>();
        program.exercises.forEach(e => {
                exercises.set(e.label, []);
        })

        g.sessions.forEach(s => {
            if (s.programName !== programName) {
                return;
            }
            s.completedExercises.forEach(e => {
                if (!exercises.get(e.label)) {
                    exercises.set(e.label, []);
                }
                exercises.get(e.label)?.push(e);
            })
        })
        exercises.forEach((value, key) => {
            if (!exgroups.get(key)) {
                exgroups.set(key, []);
            }
            exgroups.get(key)?.push({start: g.start, end: g.end, exercises: value})
        })
    })

    // Now our input parameters are in a workable format, and the following procedure is similar to the one in sessionCountData()
    exgroups.forEach((groups, key) => {
        const data = groups.map((g) => {
            const max = g.exercises.reduce((max: number, exercise) =>  Math.max(max, (exercise.sessionValues.reduce((a, b) => Math.max(a,b), 0))), 0)
            return {x: g.start, y: max}
        })
        datasets.set(key, data);
    })

    return datasets
}

export function groupByInterval(sessions: session[], interval: interval) {
    sessions = [...sessions];
    sessions.sort((a,b) => a.startTime.getTime() - b.startTime.getTime())
    const groups: Array<sessionGroup> = [];

    if (sessions[0] === undefined) {
        return groups;
    }

    const earliest = sessions[0].startTime;
    let startSlot: Date;

    /*
    Each interval (week, month, year) has canonical timeslots for which sessions are grouped inbetween. 
    For example, if the interval to group sessions in is "week", each session is placed in an intervalgroup with timeslot (that is start- and end-date)
    such that the start-date is the previous monday at 00:00 o'clock, and the end-date is the next monday at 00:00 o'clock. 
    We here find and set the earliest canonical start-slot we need to use.
    */
    switch(interval) {
        case("week"): {
            startSlot = new Date(earliest.getFullYear(), earliest.getMonth(), earliest.getDate() - ((earliest.getDay() + 6) % 7)); //Last tidbit is used to ensure startSlot date is the previous MONDAY
            break;
        }
        case("month"): {
            startSlot = new Date(earliest.getFullYear(), earliest.getMonth());
            break;
        }
        case("year"): {
            startSlot = new Date(earliest.getFullYear(), 0); //second index (month) is needed: see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
            break;
        }
    }

    const endSlot = new Date(startSlot);
    dateIncrement(endSlot, interval);
    groups.push({start: new Date(startSlot), end: new Date(endSlot), sessions: []});

    sessions.forEach(e => {
        while (e.startTime.getTime() >= endSlot.getTime()) {
            dateIncrement(startSlot, interval);
            dateIncrement(endSlot, interval);
            groups.push({start: new Date(startSlot), end: new Date(endSlot), sessions: []});
        }
        groups[groups.length - 1].sessions.push(e);
    });

    return groups;
}

export function dateIncrement(date: Date, interval: interval, n=1) {
    switch(interval) {
        case("week"): {
            date.setTime(date.getTime() + 604800000*n);
            break;
        }

        case("month"): {
            date.setMonth(date.getMonth() + n);
            break;

        }
        case("year"): {
            date.setFullYear(date.getFullYear() + n);
            break;
        }
    }
}

/**
 * 
 * @param data sessionGroup array obtained by using {@link groupByInterval} on a session array
 * @param interval the interval the groups are grouped by
 * @param intervalsToShow the range of intervals to show.
 * @returns An initial zoomdomain to use when plotting elements from `groups`. For example, if interval == `"week"`
 * and intervalsToShow=`8`, returns a zoomdomain [date1, date2] where date1 is 8 weeks prior to
 * date2, and date2 is the latest date in the groups array if a range of such a size could be
 * found among the elements in the groups array. Otherwise, returns the greatest range found.

 */
export function getZoomDomain(data: chartData[], interval: interval, intervalsToShow=1): [Date, Date] {
    if (data.length < 1) {
        return [new Date(), new Date()]
    }

    const domain = [data[0].x, data[0].x]

    data.forEach(g => {
        if (g.x.getTime() < domain[0].getTime()) {
            domain[0] = g.x;
        }
        if (g.x.getTime() > domain[1].getTime()) {
            domain[1] = g.x;
        }
    })

    const start = new Date(domain[1])
    dateIncrement(start, interval, -intervalsToShow)

    if (domain[0].getTime() <= start.getTime()) {
        domain[0] = start;
    }
    return [domain[0], domain[1]];
}
