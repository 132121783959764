import { Link } from "react-router-dom";
import { NavLinkType } from "../../helpers/nav/navLinks";

type navLinkProps = {
    navLink: NavLinkType;
};

export const NavLinkItem = ({ navLink }: navLinkProps) => (
    <li key={navLink.label} className="hover:text-orange-500">
        <Link to={navLink.url} key={navLink.label}>
            {navLink.label}
        </Link>
    </li>
);
