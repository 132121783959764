import { getDoc, doc } from "firebase/firestore";
import { target, user } from "../../../helpers/business/interfaces";
import { firestore } from "../../firebaseConfig";

export const getTarget = async (UID: string) => {
    const docRef = doc(firestore, "target", UID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data() as target;
    } else {
        console.log("No such document!");
        return null;
    }
};
