import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import { LogInInputs } from "../components/login/logInInputs";
import { UserAuth } from "../context/authContext";
import runnersImg from "../img/runners.png";

export const LogIn = () => {
    const { user } = UserAuth();
    const navigation = useNavigate();

    if (user) {
        navigation("/home");
    }

    return (
        <div className="mt-8">
            <Header text="Logg inn" />
            <div className="flex">
                <LogInInputs />
                <img src={runnersImg} alt="logo" className="w-1/3 mx-auto" />
            </div>
        </div>
    );
};
