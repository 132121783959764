import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { getProgram } from "../../firebase/firestore/program/getProgram";
import { programDB } from "../../firebase/firestore/program/getPrograms";
import {
    getSessions,
    sessionDB,
} from "../../firebase/firestore/sessions/getSessions";

interface ProgramCardProps {
    programname: string;
    target: number;
}
export const ProgramCard = ({ programname, target }: ProgramCardProps) => {
    const [sessions, setSessions] = useState<sessionDB[]>([]);
    const [program, setProgram] = useState<programDB | null>(null);

    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser) {
            getSessions(auth.currentUser.uid).then((sessions) => {
                setSessions(sessions);
            });
            getProgram(programname).then((program) => {
                setProgram(program);
            });
        }
    }, [auth.currentUser]);

    const sessionsThisWeek = sessions.filter((s) => {
        const date = new Date(s.startTime);
        const today = new Date();
        const diff = Math.abs(date.getTime() - today.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays < 7 && s.programName + "#" + s.owner === programname;
    });

    const ratio = sessionsThisWeek.length / target;
    const color = ratio >= 1 ? "#52CC7A" : "rgb(147 197 253)";
    const style = {
        width: ratio * 100 + "%",
        maxWidth: "100%",
        backgroundColor: color,
    };

    return (
        <div className="w-1/3 p-2">
            <div className="w-full shadow-md bg-white dark:bg-slate-800 dark:shadow-slate-500 dark:shadow-sm">
                <p className="text-md bg-blue-300 p-2 pl-4 dark:text-slate-800">
                    {program?.name}
                </p>
                <div className="p-4 pt-2">
                    <p className="mb-2">
                        Mål: {target} {target === 1 ? "gang" : "ganger"} i uken
                    </p>
                    <p className="mb-2">
                        Status: {sessionsThisWeek.length}{" "}
                        {sessionsThisWeek.length === 1 ? "gang" : "ganger"}{" "}
                        denne uken
                    </p>
                    <div className="border-2 w-full h-8 mb-2 rounded-full bg-slate-200 dark:bg-slate-500 dark:border-slate-800">
                        {ratio === 0 ? (
                            <div className="w-8 h-full rounded-full bg-red-300 text-right p-0.5 pr-1 dark:text-slate-800">
                                {(ratio * 100).toFixed()}%
                            </div>
                        ) : (
                            <div
                                className={`h-full rounded-full text-right p-0.5 pr-2 dark:text-slate-800`}
                                style={style}
                            >
                                {(ratio * 100).toFixed()}%
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
