import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBqLvgHo5L50bmtPCxS8YxOCY1Ki2MlRNs",
    authDomain: "tdt4140-gruppe-6.firebaseapp.com",
    projectId: "tdt4140-gruppe-6",
    storageBucket: "tdt4140-gruppe-6.appspot.com",
    messagingSenderId: "816388577032",
    appId: "1:816388577032:web:c683b5d3f731e481eafebd",
    measurementId: "G-QZT7CNM6SK",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

export default app;
