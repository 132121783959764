import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import SubHeader from "../components/subHeader";
import { SearchCard } from "../components/users/searchCard";
import { UserCard } from "../components/users/userCard";
import { getUser } from "../firebase/firestore/users/getUserData";
import { getUsers } from "../firebase/firestore/users/getUsers";
import { user } from "../helpers/business/interfaces";
import { darkModeStyle } from "../helpers/style/darkmode";

export const UserSearch = () => {
    const [searchValue, setSearchValue] = useState("");
    const [users, setUsers] = useState<user[]>([]);
    const [currentUser, setCurrentUser] = useState<user | null>(null);

    const [loading, setLoading] = useState(true);
    const [triggerUpdate, setTriggerUpdate] = useState(false);

    const auth = getAuth();
    useEffect(() => {
        if (auth.currentUser) {
            getUsers(auth.currentUser.uid)
                .then((users) => {
                    setUsers(users);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            getUser(auth.currentUser.uid)
                .then((user) => {
                    setCurrentUser(user);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [triggerUpdate, auth.currentUser]);

    return (
        <div className={`mt-8 ${loading && "hidden"}`}>
            <Header text={"Brukersøk"} />
            <div className="mt-4">
                <input
                    className={`border-2 border-gray-300 p-4 rounded-t-lg ${
                        searchValue ? "rounded-t-lg" : "rounded-lg"
                    } w-full ${darkModeStyle}`}
                    type="search"
                    name="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Legg til nye brukere"
                />
                {searchValue && (
                    <div className="border-b-2 border-l-2 border-r-2 border-gray-300 w-full overflow-y-scroll rounded-b-lg max-h-48">
                        <ul>
                            {users
                                .filter(
                                    (user) =>
                                        user.username
                                            .toLowerCase()
                                            .includes(
                                                searchValue.toLowerCase()
                                            ) ||
                                        user.firstname
                                            .toLowerCase()
                                            .includes(
                                                searchValue.toLowerCase()
                                            ) ||
                                        user.lastname
                                            .toLowerCase()
                                            .includes(searchValue.toLowerCase())
                                )
                                .map((user) => (
                                    <li
                                        key={user.UID}
                                        className="even:bg-slate-100 dark:even:bg-slate-700"
                                    >
                                        <Link
                                            key={user.UID}
                                            to={`/user?uid=${user.UID}`}
                                            id={user.UID}
                                        >
                                            <SearchCard
                                                props={user}
                                                thisUser={currentUser}
                                                border-2
                                                border-slate-300
                                            />
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="flex gap-4 mt-8">
                <div className="w-1/2 mt-4">
                    <SubHeader
                        text={`Du følger: (${currentUser?.following.length})`}
                    />
                    <div className="mt-4 h-96 overflow-x-scroll rounded-lg border-2 border-slate-300 ">
                        <ul>
                            {currentUser &&
                                currentUser.following.map((uid) => (
                                    <li
                                        key={uid}
                                        className="even:bg-slate-100 dark:even:bg-slate-700"
                                    >
                                        <UserCard
                                            key={uid}
                                            uid={uid}
                                            enableUnfollow={true}
                                            currentUser={currentUser}
                                            triggerUpdate={setTriggerUpdate}
                                        />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
                <div className="w-1/2 mt-4">
                    <SubHeader
                        text={`Dine følgere: (${currentUser?.followers.length})`}
                    />
                    <div className="mt-4 h-96 rounded-lg overflow-x-scroll border-2 border-slate-300">
                        <ul>
                            {currentUser &&
                                currentUser.followers.map((uid) => (
                                    <li
                                        key={uid}
                                        className="even:bg-slate-100 dark:even:bg-slate-700"
                                    >
                                        <UserCard
                                            key={uid}
                                            uid={uid}
                                            enableUnfollow={false}
                                            currentUser={currentUser}
                                            triggerUpdate={setTriggerUpdate}
                                        />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
