import { Route, Routes } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase/firebaseConfig";
import { LogIn } from "./pages/logIn";
import { CreateUser } from "./pages/createUser";
import { Profile } from "./pages/profile";
import { AuthContextProvider } from "./context/authContext";
import { ProtectedRoute } from "./components/protectedRoute";
import { About } from "./pages/about";
import { CreateWorkoutProgram } from "./pages/createWorkoutProgram";
import { Sessions } from "./pages/sessions";
import SessionForm from "./components/program/sessionForm";
import { Programs } from "./pages/programs";
import { Navbar } from "./components/nav/navbar";
import { navLinks } from "./helpers/nav/navLinks";
import { UserSearch } from "./pages/userSearch";
import { UserPage } from "./pages/userPage";
import { Home } from "./pages/feed";
import { CreateTarget } from "./pages/createTarget";

initializeApp(firebaseConfig);

export const App = () => {
    return (
        <main className="dark:text-slate-300 dark:bg-slate-800 min-h-screen -mb-16 pb-16">
            <AuthContextProvider>
                <Navbar navLinks={navLinks} />
                <div className="ml-auto mr-auto max-w-4xl">
                    <Routes>
                        <Route path="/" element={<LogIn />} />
                        <Route path="/create-user" element={<CreateUser />} />
                        <Route
                            path="/profile"
                            element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/about"
                            element={
                                <ProtectedRoute>
                                    <About />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/home"
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/programs"
                            element={
                                <ProtectedRoute>
                                    <Programs />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/createWorkoutProgram"
                            element={
                                <ProtectedRoute>
                                    <CreateWorkoutProgram />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/sessions"
                            element={
                                <ProtectedRoute>
                                    <Sessions />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/createSession"
                            element={
                                <ProtectedRoute>
                                    <SessionForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/userSearch"
                            element={
                                <ProtectedRoute>
                                    <UserSearch />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/user"
                            element={
                                <ProtectedRoute>
                                    <UserPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/createTarget"
                            element={
                                <ProtectedRoute>
                                    <CreateTarget />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </div>
            </AuthContextProvider>
        </main>
    );
};
