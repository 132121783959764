/**
 * Maps a list of strings into a list of integers. Invalid strings are converted to `fallback`.
 * 
 * @param ls - A list of strings to be parsed into integers.
 * @param fallback - Optional. If a string is invalid, convert it to `fallback`. Defaults to 0 if not given.
 * @returns A list of integers. If a string is not a valid integer, it is converted to `fallback`.
 */
export function stringsToInts(ls: string[], fallback = 0) {
    let n: number;
    return ls.map(e => {
        n = parseInt(e);
        return n ? n : fallback;
    });
}