import { useState } from "react"

interface SelectProps<T> {
    choices: T[]
    onChange: (e: T) => void
    direction?: "horizontal"|"vertical"
    className?: string
    color?: string
}

//TODO: Fix SelectProps<any> to actually use the <T> generic properly ~~ right now, it seems we are just avoiding typescript type-checks, sadly
////    should look up in the docs how to use generics properly
export const SlideSelect = ({choices, onChange, direction="vertical", className="", color="#2563eb60"}: SelectProps<any>) => {
    const [idx, setIdx] = useState(0);
    const [choice, setChoice] = useState(choices[0])

    const handleUp = () => {
        const newIdx = (idx+1) % choices.length;
        setIdx(newIdx);
        setChoice(choices[newIdx]);
        onChange(choices[newIdx]);
    }
    const handleDown = () => {
        const newIdx = (idx-1 + choices.length) % choices.length;
        setIdx(newIdx);
        setChoice(choices[newIdx]);
        onChange(choices[newIdx]);
    }

    return (
    <div className={className}>
        {direction === "vertical" ? 
        <div className="flex flex-col items-center gap-1">

        <svg className="" viewBox="0 0 50 50" width="0.8em" height="0.8em" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon className="cursor-pointer" onClick={() => handleUp()} width="100%" height="100%" fill={color} strokeWidth="0" points="0,50 25,25 50,50"/>
        </svg>

        <p className="text-xl">{choice}</p>

        <svg className="" viewBox="0 0 50 50" width="0.8em" height="0.8em" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon className="cursor-pointer" onClick={() => handleDown()} width="100%" height="100%" fill={color} strokeWidth="0" points="0,0 25,25 50,0"/>
        </svg>

        </div>
        :
        <div className="flex flex-row items-center justify-between gap-2">
        <svg className="" viewBox="0 0 50 50" width="0.8em" height="0.8em" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon className="cursor-pointer" onClick={() => handleDown()} width="100%" height="100%" fill={color} strokeWidth="0" points="50,0 25,25 50,50"/>
        </svg>

        <p className="text-xl">{choice}</p>

        <svg className="" viewBox="0 0 50 50" width="0.8em" height="0.8em" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon className="cursor-pointer" onClick={() => handleUp()} width="100%" height="100%" fill={color} strokeWidth="0" points="0,0 0,50 25,25 0,0"/>
        </svg>
        </div>
        }

    </div>
    )
}
