import { useState } from "react"
import { program, session } from "../../helpers/business/interfaces"
import { groupByInterval, interval, sessionCountData, sessionGroup, sessionMaxWeights } from "../../helpers/stats/sessionCount"
import { SlideSelect } from "../input/slideSelect"
import { chartData, IntervalChart } from "./chart"

interface intervalTranslations {
    "Uke": "week",
    "Måned": "month",
    "År": "year",
}
const intervalTranslations: intervalTranslations = {
    "Uke": "week",
    "Måned": "month",
    "År": "year",
}
type intervalTranslate = keyof intervalTranslations


interface dataTranslations {
    "Antall økter": "sessionCount",
    "Maksløft": "sessionWeight"
}
const dataTranslations: dataTranslations = {
    "Antall økter": "sessionCount",
    "Maksløft": "sessionWeight"
}
type data =  dataTranslations[keyof dataTranslations]
type dataTranslate = keyof dataTranslations

const dataFunctions: { [key in data]: ((e: sessionGroup[], p: program) => chartData[] | Map<string, chartData[]>) } = {
    "sessionCount": sessionCountData,
    "sessionWeight": sessionMaxWeights
}

interface chartSelectProps {
    sessions: session[],
    program: program
    className?: string
}

export const ChartSelect = ({sessions, program, className=""}: chartSelectProps) => {
    const [dataShown, setDataShown] = useState<data>("sessionCount");
    const [interval, setInterval] = useState<interval>("week");

    return (
        <div className={`flex flex-col items-center ${className}`}>
        { groupByInterval(sessions, interval).length >= 1? 
            <>
            <div className="flex flex-row flex-grow items-center">
            <SlideSelect 
                className="w-32 mr-[-32px]" 
                choices={Object.keys(dataTranslations)} 
                direction="vertical"
                onChange={((e:dataTranslate) => setDataShown(dataTranslations[e]))}
                />
            <IntervalChart
                key={dataShown + interval}
                data={dataFunctions[dataShown](groupByInterval(sessions, interval), program)}
                interval={interval}
            />
            </div>
            <SlideSelect 
                className="ml-32 w-40" 
                choices={Object.keys(intervalTranslations)} 
                direction="horizontal" 
                onChange={(e:intervalTranslate) => setInterval(intervalTranslations[e])}/>
            </>
        : <></>}
        </div>
    )
}
