import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";

const userDB = collection(firestore, "users");

// This is just a helper to add data to the firestore database

export const postUser = async (
    UID: string,
    firstname: string,
    lastname: string,
    email: string,
    username: string,
    followers: string[],
    following: string[]
) => {
    await setDoc(doc(userDB, UID), {
        UID: UID,
        firstname: firstname,
        lastname: lastname,
        email: email,
        username: username,
        following: following,
        followers: followers,
    });
};
