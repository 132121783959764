import { UserCredential } from "firebase/auth";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../components/header";
import { UserAuth } from "../context/authContext";
import { postUser } from "../firebase/firestore/users/userDB";
import { darkModeStyle } from "../helpers/style/darkmode";

export const CreateUser = () => {
    const [firstName, setFirstName] = useState("");
    const [validFirstName, setValidFirstName] = useState(true);
    const [lastName, setLastName] = useState("");
    const [validLastName, setValidLastName] = useState(true);
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [username, setUsername] = useState("");
    const [validUsername, setValidUsername] = useState(true);
    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(true);
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [validPasswordRepeat, setValidPasswordRepeat] = useState(true);

    const { createUser } = UserAuth();
    const navigate = useNavigate();

    const handleCreateUser = () => {
        let validForm = true;
        if (firstName.length < 2) {
            setValidFirstName(false);
            validForm = false;
        } else {
            setValidFirstName(true);
        }
        if (lastName.length < 2) {
            setValidLastName(false);
            validForm = false;
        } else {
            setValidLastName(true);
        }
        if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
            setValidEmail(false);
            validForm = false;
        } else {
            setValidEmail(true);
        }
        if (username.length < 2) {
            setValidUsername(false);
            validForm = false;
        } else {
            setValidUsername(true);
        }
        if (password.length < 6) {
            setValidPassword(false);
            validForm = false;
        } else {
            setValidPassword(true);
        }
        if (password !== passwordRepeat || passwordRepeat.length === 0) {
            setValidPasswordRepeat(false);
            validForm = false;
        } else {
            setValidPasswordRepeat(true);
        }
        console.log(
            "Valid first name: ",
            validFirstName,
            "Valid last name: ",
            validLastName,
            "Valid email: ",
            validEmail,
            "Valid username: ",
            validUsername,
            "Valid password: ",
            validPassword,
            "Valid password repeat: ",
            validPasswordRepeat,
            "Valid form: ",
            validForm
        );
        if (validForm) {
            createNewUser();
        }
    };

    const createNewUser = async () => {
        try {
            await createUser(email, password).then((user: UserCredential) => {
                postUser(
                    user.user.uid,
                    firstName,
                    lastName,
                    email,
                    username,
                    [],
                    []
                );
            });
            navigate("/profile");
        } catch (error) {
            console.log(error);
        }
    };

    const inputStyle = `border-2 border-gray-300 p-2 rounded-lg w-full mt-4`;

    return (
        <div className="mt-8">
            <Header text="Ny bruker" />
            <input
                type="text"
                placeholder="Fornavn"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={`${inputStyle} ${darkModeStyle}
                    ${!validFirstName && "border-red-500 bg-red-100 "} 
                 `}
            />
            <input
                type="text"
                placeholder="Etternavn"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={`${inputStyle} ${darkModeStyle}
                    ${!validLastName && "border-red-500 bg-red-100 "} 
                    `}
            />
            <input
                type="text"
                placeholder="E-post"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${inputStyle} ${darkModeStyle}
                    ${!validEmail && "border-red-500 bg-red-100 "} 
                    `}
            />
            <input
                type="text"
                placeholder="Brukernavn"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className={`${inputStyle} ${darkModeStyle}
                    ${!validUsername && "border-red-500 bg-red-100 "} 
                    `}
            />
            <input
                type="password"
                placeholder="Passord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`${inputStyle} ${darkModeStyle}
                    ${!validPassword && "border-red-500 bg-red-100 "} 
                    `}
            />
            <input
                type="password"
                placeholder="Gjenta passord"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                className={`${inputStyle} ${darkModeStyle}
                    ${!validPasswordRepeat && "border-red-500 bg-red-100 "} 
                    `}
            />
            <button
                className="bg-orange-600 text-white p-2 rounded-lg w-full mt-4"
                onClick={handleCreateUser}
            >
                Opprett bruker
            </button>
            <NavLink to="/">
                <button className="text-orange-600 p-2 w-full mt-4 border border-orange-500 rounded-lg">
                    Avbryt
                </button>
            </NavLink>
        </div>
    );
};
