import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/authContext";

export const LogOutButton = () => {
    const navigate = useNavigate();
    const { logOut } = UserAuth();

    const handleLogOut = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <button
            className="mt-1 float-right mx-4 hover:text-orange-500"
            onClick={handleLogOut}
        >
            Logg ut
        </button>
    );
};
