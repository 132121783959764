import { useState } from "react";

interface DetailViewProps {
    children: JSX.Element[] | JSX.Element;
    color?: "orange" | "blue";
}

export const DetailView = ({ children, color = "orange" }: DetailViewProps) => {
    const [showResults, setShowResults] = useState(false);

    const onClick = () => {
        setShowResults((prevState) => !prevState);
    };

    const blue = `bg-blue-500 text-white p-2 rounded-lg w-32 hover:bg-blue-400 dark:text-blue-500 border border-blue-500 dark:bg-transparent hover:dark:bg-blue-500 hover:dark:text-slate-800`;
    const orange = `bg-orange-500 text-white p-2 rounded-lg w-32 hover:bg-orange-400 dark:text-orange-500 border border-orange-500 dark:bg-transparent hover:dark:bg-orange-500 hover:dark:text-slate-800`;

    return (
        <div className="mt-4 w-100%">
            <button
                className={color == "blue" ? blue : orange}
                type="submit"
                onClick={onClick}
            >
                {showResults ? "Skjul detaljer" : "Vis detaljer"}
            </button>
            {showResults ? <div>{children}</div> : null}
        </div>
    );
};
