import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import Header from "../components/header";
import { TargetCard } from "../components/target/targetCard";
import { ProgramCard } from "../components/program/programCard";
import { SessionCard } from "../components/program/sessionCard";
import SubHeader from "../components/subHeader";
import { programDB } from "../firebase/firestore/program/getPrograms";
import { getProgramsFromUsers } from "../firebase/firestore/program/getProgramsFromUsers";
import { getSessionsFromUsers } from "../firebase/firestore/sessions/getSessionsFromUsers";
import { getUser } from "../firebase/firestore/users/getUserData";
import { session } from "../helpers/business/interfaces";
import { darkModeStyle } from "../helpers/style/darkmode";
import crossfit from "../img/crossfit_banner.jpg";
import pf from "../img/pf.jpeg";

export const Home = () => {
    const [sessions, setSessions] = useState<session[]>([]);
    const [programs, setPrograms] = useState<programDB[]>([]);

    const [showSessions, setShowSessions] = useState(true);
    const [showPrograms, setShowPrograms] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const [sortSessions, setSortSessions] = useState("newest");
    const [sortPrograms, setSortPrograms] = useState("newest");

    const [loading, setLoading] = useState(true);

    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser) {
            getUser(auth.currentUser.uid)
                .then((user) => {
                    if (user) {
                        getSessionsFromUsers(user.following, setSessions);
                        getProgramsFromUsers(user.following, setPrograms);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [auth.currentUser]);

    const toggleShowPrograms = () => {
        setShowPrograms(!showPrograms);
    };

    const toggleShowSessions = () => {
        setShowSessions(!showSessions);
    };

    const handleSortSessions = (e: any) => {
        setSortSessions(e.target.value);
        if (e.target.value === "newest") {
            setSessions((data) => {
                const dataToSort = [...data];
                dataToSort.sort(
                    (a, b) =>
                        Number(b.dateCreated.getTime()) -
                        Number(a.dateCreated.getTime())
                );
                return dataToSort;
            });
        } else if (e.target.value === "oldest") {
            setSessions((data) => {
                const dataToSort = [...data];
                dataToSort.sort(
                    (a, b) =>
                        Number(a.dateCreated.getTime()) -
                        Number(b.dateCreated.getTime())
                );
                return dataToSort;
            });
        }
    };

    const handleSortPrograms = (e: any) => {
        setSortPrograms(e.target.value);
        if (e.target.value === "newest") {
            setPrograms((data) => {
                const dataToSort = [...data];
                dataToSort.sort(
                    (a, b) =>
                        Number(b.dateCreated.getTime()) -
                        Number(a.dateCreated.getTime())
                );
                return dataToSort;
            });
        } else if (e.target.value === "oldest") {
            setPrograms((data) => {
                const dataToSort = [...data];
                dataToSort.sort(
                    (a, b) =>
                        Number(a.dateCreated.getTime()) -
                        Number(b.dateCreated.getTime())
                );
                return dataToSort;
            });
        }
    };

    return (
        <div className={`mt-8 ${loading && "hidden"}`}>
            <Header text={"Hjem"} />
            <img src={crossfit} alt="crossfit" className="w-full my-4" />
            <section className=" shadow-md p-4 my-8 bg-zinc-100 dark:bg-slate-700">
                <TargetCard />
            </section>
            <section className="">
                <SubHeader text="Ukens annonse:" />
                <a
                    href="https://www.proteinfabrikken.no/pasketilbud"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={pf} alt="ad" className="w-full my-4" />
                </a>
            </section>
            <SubHeader text="Mine venners siste aktivitet" />
            <div>
                <input
                    className={`border-2 border-gray-300 p-4 rounded-lg mt-4 w-full ${darkModeStyle}`}
                    type="search"
                    name="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Søk etter innhold"
                />
            </div>
            <div className="flex w-1/2 gap-8 my-4">
                <div className="flex gap-1">
                    <input
                        type="checkbox"
                        checked={showSessions}
                        onChange={toggleShowSessions}
                    />
                    <p>Vis økter</p>
                </div>
                <div className="flex gap-1">
                    <input
                        type="checkbox"
                        checked={showPrograms}
                        onChange={toggleShowPrograms}
                    />
                    <p>Vis programmer</p>
                </div>
            </div>
            <div className="flex gap-8 mt-8">
                {!showSessions && !showPrograms && (
                    <p className="text-center text-xl italic">
                        Ingen innhold å vise - velg hvilket innhold du vil se
                    </p>
                )}
                <div
                    className={`${showPrograms && showSessions && "w-1/2 "} 
                    ${!showPrograms && showSessions && "w-full "}
                    ${showPrograms && !showSessions && "hidden "}
                    ${!showPrograms && !showSessions && "hidden "}
                }`}
                >
                    <div className="flex justify-between">
                        <SubHeader text={`Økter: (${sessions.filter(s => s.visibility === "public" || s.visibility == "followers" || s.visibility === undefined).length}) `} />
                        <select
                            value={sortSessions}
                            onChange={handleSortSessions}
                            className={`border-2 border-gray-300 p-1 rounded-lg w-1/8 ${darkModeStyle}`}
                        >
                            <option value="newest">Nyligst</option>
                            <option value="oldest">Eldst</option>
                        </select>
                    </div>
                    {sessions.length === 0 && (
                        <p className="text-xl italic mt-2 opacity-40">
                            Ingen økter å vise
                        </p>
                    )}
                    {searchValue &&
                        sessions
                            .filter(
                                (session) =>
                                    session.programName
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase()) &&
                                    (session.visibility === "public" ||
                                        session.visibility === "followers" ||
                                        session.visibility === undefined)
                            )
                            .map((session) => {
                                return (
                                    <SessionCard
                                        key={
                                            session.startTime.toString() +
                                            session.programName
                                        }
                                        session={session}
                                    />
                                );
                            })}
                    {!searchValue &&
                        sessions.filter((session) => 
                                    session.visibility === "public" ||
                                    session.visibility === "followers" ||
                                    session.visibility === undefined
                        )
                        .map((session) => {
                            return (
                                <SessionCard
                                    key={
                                        session.startTime.toString() +
                                        session.programName
                                    }
                                    session={session}
                                />
                            );
                        })}
                </div>
                <div
                    className={`${showPrograms && showSessions && "w-1/2 "} 
                    ${!showPrograms && showSessions && "hidden "}
                    ${showPrograms && !showSessions && "w-full "}
                    ${!showPrograms && !showSessions && "hidden "}
                }`}
                >
                    <div className="flex justify-between">
                        <SubHeader text={`Program: (${programs.filter(p => p.visibility === "public" || p.visibility === "followers" || p.visibility === undefined).length}) `} />
                        <select
                            value={sortPrograms}
                            onChange={handleSortPrograms}
                            className={`border-2 border-gray-300 p-1 rounded-lg w-1/8 ${darkModeStyle}`}
                        >
                            <option value="newest">Nyligst</option>
                            <option value="oldest">Eldst</option>
                        </select>
                    </div>
                    {programs.length === 0 && (
                        <p className="text-xl italic mt-2 opacity-40">
                            Ingen program å vise
                        </p>
                    )}
                    {searchValue &&
                        programs
                            .filter(
                                (program) =>
                                    (program.name
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase()) ||
                                    (program.category
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase()))) 
                                    &&
                                        (program.visibility === "public" ||
                                        program.visibility === "followers" ||
                                        program.visibility === undefined)
                            )
                            .map((program) => {
                                return (
                                    <ProgramCard
                                        key={program.name + program.owner}
                                        program={program}
                                    />
                                );
                            })}
                    {!searchValue &&
                        programs.filter((program) =>
                                    program.visibility === "public" ||
                                    program.visibility === "followers" ||
                                    program.visibility === undefined
                        )
                        .map((program) => {
                            return (
                                <ProgramCard
                                    key={program.name + program.owner}
                                    program={program}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
