import { VictoryArea, VictoryAxis, VictoryChart,VictoryClipContainer, VictoryLegend, VictoryScatter, VictoryZoomContainer } from "victory";
import { dateIncrement, getZoomDomain, interval } from "../../helpers/stats/sessionCount";
import "../../App.css";
import { useState } from "react";



export interface chartData {
    x: Date,
    y: number,
}

interface chartProps {
    data: chartData[] | Map<string, chartData[]>,
    interval: interval,
}

export const IntervalChart = ({data, interval} : chartProps) => {
    const [datas, setDatas] = useState<Map<string, chartData[]>>(() => {
        if (Array.isArray(data)) {
            const tmp = data;
            data = new Map();
            data.set("data", tmp);
        } 
        const ret = new Map(data);
        ret.forEach((v, k) => {
            if (v.length < 2) {
                const v1date = new Date(v[0].x)
                dateIncrement(v1date, interval)
                v.push({x: v1date, y: 0})
            }
        })
        console.log(ret)
        return ret
    });

    const axisColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'white' : '#000000';

    const yaxisStyle = {
        axis: { stroke: axisColor, fill: axisColor},
        tickLabels: { fontSize: 8, padding: 5, fill: axisColor },
    }
    const xaxisStyle = {
        axis: { stroke: axisColor, fill: axisColor},
        tickLabels: { fontSize: 6, padding: 5, fill: axisColor },
        ticks: { stroke: axisColor, size: 6, fill:axisColor },
    }

    return (
    <div className="h-[480px] grow">
                <VictoryChart
                    padding={55}
                    minDomain={{y: 0}}
                    containerComponent={<VictoryZoomContainer 
                        allowZoom={false}
                        responsive={true}
                        zoomDimension="x"
                        zoomDomain={{x: getZoomDomain(flattenValues(datas), interval, 8)}}
                        clipContainerComponent={<VictoryClipContainer clipPadding={{top: 100, right: 60}} />}/>}
                >
                    <VictoryAxis 
                    dependentAxis
                    style={yaxisStyle}
                    tickFormat={(t1: number) => Math.floor(t1) === t1 ? t1 : ""}
                    />
                    <VictoryAxis
                    fixLabelOverlap
                    style={xaxisStyle}
                    tickFormat={(t1: Date) => {
                        if (datas.size < 1) {
                            return "";
                        }
                        if (interval === "year") {
                            return t1.getFullYear().toString();
                        } 
                        const t2 = new Date(t1);
                        dateIncrement(t2, interval)
                        return `${t1.getDate()}/${t1.getMonth() + 1}-${t2.getDate()}/${t2.getMonth() + 1}\n${t1.getFullYear()}`
                        }}
                    tickValues={flattenValues(datas).map((e: {x: Date, y: number}) => e.x)}
                    
                    />
                    {Array.from(datas.entries()).map((e,i) => {
                        
                        return (
                        <VictoryArea
                            key={e[0]}
                            interpolation="step"
                            style={{
                                data: { stroke: rgb(i, datas.size),
                                        fill: rgb(i, datas.size, true) 
                                },
                            }}
                            data={e[1]} />
                        )
                    })
                    }
                    {Array.from(datas.entries()).map(e => {
                        return (
                        <VictoryScatter
                            key={e[0]}
                            dataComponent={<PointTooltip />}
                            data={e[1]} />
                        )
                    })
                    }
                    { datas.size > 1 &&
                    <VictoryLegend x={45} y={270}
                        gutter={Array.from(datas.keys()).reduce((acc, cur) => Math.max(acc, cur.length), 0) + 20}
                        orientation="horizontal"
                        style= {{labels: {fontSize: 9}}}
                        data = {
                            Array.from(datas.entries()).map((e,i) => {return { name: e[0], symbol: {fill: rgb(i, datas.size)} }})
                            }
                    />
                    }
                </VictoryChart>
                </div>
)
}

function PointTooltip(props: any) {
    function getEmoji(y: number) {
        switch(y) {
//            case 0: return ""
//            case 1: return "🥉"
//            case 2: return "🥈"
//            case 3: return "🥇"
//            case 4: return "😎"
//            case 5: return "😲"
//            case 6: return "😱"
//            default: return "🤯"
            default: return " "
        }

    }
    return (
       <text x={props.x-12.5} y={props.y+20} fontSize={25}>
            {getEmoji(props.datum._y)}
      </text> 
    )
}

//denne bør kanskje flyttes til annen fil
function flattenValues<T,S>(map: Map<T, S[]>) {
    const ret: S[] = [];
    map.forEach((arr, key) => {
        arr.forEach(val => {
            ret.push(val);
        })
    })
    return ret;
}

function rgb(i: number, max: number, alpha?: boolean) {
    const r = max==1 ? 50 : 100+ Math.sin((i/max) * 2 * Math.PI  ) * 150
    const g = max==1 ? 100 : 100+ Math.cos((i/max) * 2 * Math.PI  ) * 150
    const b = max==1 ? 200 : 170

    if (alpha){
        return `rgb(${r}, ${g}, ${b}, ${(max)/(max*(i+4)**1.8)})`
    }

    return `rgb(${r}, ${g}, ${b})`
}
