import { user } from "../../helpers/business/interfaces";
import { getAuth } from "firebase/auth";
import NavButton from "../navButton";
import SubHeader from "../subHeader";
import { FollowButton } from "./followButton";
import { useEffect, useState } from "react";
import {
    getPrograms,
    programDB,
} from "../../firebase/firestore/program/getPrograms";
import {
    getSessions,
    sessionDB,
} from "../../firebase/firestore/sessions/getSessions";
import { Personalia } from "./personalia";
import { TrainingData } from "./trainingData";
import { Follwers } from "./followers";
import { getUser } from "../../firebase/firestore/users/getUserData";
import profilbilde from "../../img/profilbilde.jpeg";

interface ProfileCardProps {
    userData?: user | null;
}

export const ProfileCard = ({ userData }: ProfileCardProps) => {
    const auth = getAuth();
    const currentUID = auth.currentUser?.uid;
    const [programs, setPrograms] = useState<programDB[] | null>(null);
    const [sessions, setSessions] = useState<sessionDB[] | null>(null);
    const [currentUser, setCurrentUser] = useState<user | null>(null);

    useEffect(() => {
        if (auth.currentUser && userData) {
            getPrograms(userData.UID).then((programs) => {
                setPrograms(programs);
            });
            getSessions(userData.UID).then((s) => {
                setSessions(s);
            });
            getUser(auth.currentUser.uid).then((user) => {
                setCurrentUser(user);
            });
        }
    }, [auth.currentUser, userData]);

    return (
        <>
            {!userData && <p className="italic">Ingen bruker funnet</p>}
            {userData && (
                <div className="border-t-8 border-orange-400 p-8 pt-6 my-8 shadow-md dark:shadow-slate-500 dark:shadow-sm">
                    <div className="flex justify-between">
                        <SubHeader text={userData.username} />
                        {currentUID === userData.UID ? (
                            <></>
                        ) : (
                            <FollowButton
                                userData={userData}
                                currentUser={currentUser}
                            />
                        )}
                    </div>
                    <div className="flex gap-4">
                        <img
                            src={profilbilde}
                            className="h-24 mt-4 mr-4 rounded-full"
                        ></img>
                        <Personalia userData={userData} />
                        <TrainingData
                            userData={userData}
                            sessions={sessions}
                            programs={programs}
                        />
                        <Follwers userData={userData} />
                    </div>
                </div>
            )}
        </>
    );
};
