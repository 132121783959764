import { user } from "../../helpers/business/interfaces";
import profileImg from "../../img/profilbilde.jpeg";

interface SearchCardProps {
    props: user;
    thisUser: user | null;
}

export const SearchCard = ({ props: user }: SearchCardProps) => {
    return (
        <div className=" p-4">
            <div className="flex justify-between">
                <div className="flex">
                    <img
                        className="w-12 h-12 rounded-full"
                        src={profileImg}
                        alt="user"
                    />
                    <div className="ml-4">
                        <p className="font-bold">
                            {user.firstname} {user.lastname}
                        </p>
                        <p className="text-gray-500">
                            {user.username} - {user.email}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
