import { sessionDB } from "../../firebase/firestore/sessions/getSessions";
import Header from "../header";
import { SessionCard } from "./sessionCard";

interface OtherUsersSessionsProps {
    sessions: sessionDB[];
    currentUser: string;
    followers: string[];
}

export const OtherUsersSessions = ({
    sessions,
    currentUser,
    followers,
}: OtherUsersSessionsProps) => {
    return (
        <div className="mt-8">
            <div className="flex justify-between">
                <Header text={"Treningsøkter"} />
            </div>
            {sessions
                .filter(
                    (s) =>
                        s.visibility == "public" ||
                        (s.visibility == "followers" &&
                            followers.includes(currentUser))
                )
                .sort(
                    (o1, o2) =>
                        o2.dateCreated.getTime() - o1.dateCreated.getTime()
                )
                .map((session) => {
                    return (
                        <ul
                            key={
                                session.programName +
                                session.startTime.toISOString() +
                                session.owner
                            }
                        >
                            <SessionCard session={session} />
                        </ul>
                    );
                })}
            {sessions.length == 0 && (
                <p className="italic mt-4">Ingen registrerte treningsøkter</p>
            )}
        </div>
    );
};
