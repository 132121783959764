import {
    query,
    where,
    collection,
    getDocs,
    Timestamp,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { program } from "../../../helpers/business/interfaces";

export interface programDB extends program {
    owner: string;
}

export const getPrograms = async (UID: string) => {
    // Get documents from collection "programs" where the user is a member of the document ID
    const res: programDB[] = [];

    const programs = collection(firestore, "program");
    const q = query(programs, where("owner", "==", UID));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const data = doc.data() as programDB;
        data.dateCreated = new Date(
            (data.dateCreated as unknown as Timestamp).seconds * 1000
        );
        res.push(data);
    });
    return res;
};
