import { Link } from "react-router-dom";
import { UserAuth } from "../../context/authContext";
import { NavLinkType } from "../../helpers/nav/navLinks";

import { LogOutButton } from "./logOutButton";
import { NavLinkItem } from "./navLinkItem";

interface NavbarProps {
    navLinks: NavLinkType[];
}

export const Navbar = ({ navLinks }: NavbarProps) => {
    const { user } = UserAuth();
    return (
        <nav className="bg-white p-4 shadow-sm border-b dark:text-slate-300 dark:bg-slate-900 dark:border-0 ">
            <div className="max-w-4xl m-auto overflow-hidden">
                {user && (
                    <ul className="flex gap-4 mt-1 float-left">
                        {navLinks.map((navLink) => (
                            <NavLinkItem
                                navLink={navLink}
                                key={navLink.label}
                            />
                        ))}
                    </ul>
                )}
                <Link to="/home">
                    <h1 className="text-2xl text-orange-600 font-medium float-right">
                        WO Share 🧡
                    </h1>
                </Link>
                {user && <LogOutButton />}
            </div>
        </nav>
    );
};
