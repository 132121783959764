export type visibility = "private" | "followers" | "public";

export interface program {
    name: string;
    category: string;
    description: string;
    duration: number;
    intensity: number;
    exercises: exercise[];
    dateCreated: Date;

    owner?: string;
    visibility?: visibility;
}

export function emptyProgram(): program {
    return {
        name: "",
        category: "",
        description: "",
        duration: 0,
        intensity: 0,
        exercises: [], // endre denne til en map med exercise-label som keys i sprint 2?
        dateCreated: new Date(),
        visibility: "private",
    };
}

export interface exercise {
    label: string;
    sets: string;
    reps: string;
    id: number;
}

export function emptyExercise(): exercise {
    return {
        label: "",
        sets: "",
        reps: "",
        id: -1,
    };
}

//Kan eventuelt merge denne inn i exercise-interface og la nøklene være valgfrie!
export interface completedExercise extends exercise {
    //    valueUnit: string, //kg, km, etc.
    sessionValues: number[]; //[0] for set 0, [1] for set 1, ..., [set_count] for set set_count.
}
export function emptyCopmletedExercise(): completedExercise {
    return {
        ...emptyExercise(),
        //       valueUnit: "",
        sessionValues: [],
    };
}

export interface session {
    owner?: string; // user ID
    programName: string;
    description: string;
    startTime: Date;
    endTime: Date;
    dateCreated: Date;
    completedExercises: completedExercise[];
    visibility?: visibility;
}
export function emptySession(): session {
    return {
        owner: "",
        programName: "",
        description: "",
        startTime: new Date(),
        endTime: new Date(),
        dateCreated: new Date(),
        completedExercises: [],
        visibility: "private",
    };
}

export type user = {
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    UID: string;
    followers: string[];
    following: string[];
};

export interface target {
    description: string;
    programs: { [key: string]: number };
    owner: string;
}
