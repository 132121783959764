export const dateTimeToTimeSince = (date: Date) => {
    // const diff = dateDiffString(date, new Date());
    const daysDiff = Math.floor(
        (new Date().getTime() - date.getTime()) / (1000 * 3600 * 24)
    );
    const hoursDiff = Math.floor(
        (new Date().getTime() - date.getTime()) / (1000 * 3600)
    );
    const minutesDiff = Math.floor(
        (new Date().getTime() - date.getTime()) / (1000 * 60)
    );

    if (daysDiff > 0) {
        return `Publisert ${daysDiff} dager siden`;
    }
    if (hoursDiff > 0) {
        return `Publisert ${hoursDiff} timer siden`;
    }
    if (minutesDiff > 0) {
        return `Publisert ${minutesDiff} minutter siden`;
    }
    return "Nylig";
};
